import Layout from "../../layout/Layout";
import {
  getBookingType,
  getDetails
} from "../../../actions/admin/quotation";
import moment from "moment";
import arrow_right_white_icon from "../../../icons/arrow_right_white_icon.svg";
import print_white_icon from "../../../icons/print_white_icon.svg";
import tlf_logo_quotation from "../../../images/tlf-logo-quotation.png";
import collapse_up_icon from "../../../icons/collapse_up_icon.svg";
import collapse_down_icon from "../../../icons/collapse_down_icon.svg";
import PageNotFound from "../../404page/PageNotFound";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
const QuotationDownloadPage = () => {

  const settings = useSelector((state) => state.setting);
    const { lang } = settings;
    if (lang === "EN") {
      moment.locale("en");
    } else {
      moment.locale("ja");
    }
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});

  const { quotationId } = useParams();

  const [quotationDetail, setQuotationDetail] = useState(null);

  const [error, setError] = useState(false);

  const [isShow, setIsShow] = useState(false);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedJwt = jwtDecode(token);

      console.log(decodedJwt);
      setUserInfo(decodedJwt.user);
    }
    if (quotationId) {
      handleFetchDetailQuotation(quotationId);
    } else {
      setError(true);
    }
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedJwt = jwtDecode(token);
      setUserInfo(decodedJwt.user);
      setIsShow(true);
    } else {
      console.log("MUST LOGIN");
      setShowModal(true);
    }
  };

  useEffect(() => {
    console.log("quotationDetail=========");
    console.log(quotationDetail);
    if (quotationDetail !== null) {
      if (quotationDetail.user.signUpStatus !== "QUOTATION_GUEST") {
        checkToken();
      } else {
        setIsShow(true);
      }
    }
  }, [quotationDetail]);

  const handleFetchDetailQuotation = async (quotationId) => {
    try {
      var res = await getDetails(quotationId);
      if (res.bookings.length > 0) {
        setQuotationDetail(res);
      } else {
        // setError(true);
      }
    } catch (error) {
      // setError(true);
    }
  };


  const [openItems, setOpenItems] = useState(["00"]); // State to track open items

  const toggleOpen = (index) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(index)) {
        return prevOpenItems.filter((itemIndex) => itemIndex !== index); // Close the item
      } else {
        return [...prevOpenItems, index]; // Open the item
      }
    });
  };

  if (error) {
    return <PageNotFound />;
  }

  const getPhoneNumber = (item) => {
    if (item.role === "individual") {
      return `(${
        item.individualDetail.billingInformation.prefix_number ?? "-"
      })${item.individualDetail.billingInformation.phoneNumber}`;
    }
    if (item.role === "business") {
      return `(${item.businessDetail.billingInformation.prefix_number ?? "-"})${
        item.businessDetail.billingInformation.phoneNumber
      }`;
    }
    return "";
  };

  const handlePrint= () => {
    var data = [];
    for (let index = 0; index < quotationDetail?.bookings.length; index++) {
      console.log(quotationDetail?.bookings[index]?.bookingItems);
      for (let index2 = 0; index2 < quotationDetail?.bookings[index]?.bookingItems.length; index2++) {
       data.push(index  +""+ index2);
      }
      
    }
    setOpenItems(data);
    console.log(data);
    window.print();
  }

  return (
    <>
      <Layout>
        <main
          className="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-4"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div className="bg-white rounded p-3">
            {isShow && (
              <div className="container-md pt-5 r-mt-5-print">
                <div className="d-none d-md-block no-print">
                  <div className="d-flex justify-content-between mb-2 ">
                    <div className="col no-print">
                      <div className="d-flex gap-2">
                        <button
                          className="bg-dark rounded"
                          onClick={handlePrint}
                        >
                          <div className="d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                            <img src={print_white_icon} alt="" srcSet="" />
                            <p className="m-0 text-white">Print</p>
                          </div>
                        </button>
                        {/* <button className="bg-dark rounded">
                              <div className="d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                                <img src={download_white_icon} alt="" srcSet="" />
                                  <p className="m-0 text-white">Download</p>
                                </div>
                              </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* MOBILE BUTTONS */}
                <div className="d-block d-md-none no-print">
                  <div className="row mb-2 ">
                    <div className="col-12  no-print">
                      <div className="d-flex gap-2">
                        <button
                          className="bg-dark rounded"
                          onClick={handlePrint}
                        >
                          <div className="col d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                            <img src={print_white_icon} alt="" srcSet="" />
                            <p className="m-0 text-white">Print</p>
                          </div>
                        </button>
                        {/* <button className="bg-dark rounded">
                                <div className="col d-flex gap-2 px-3 py-2 justify-content-center align-items center">
                                <img src={download_white_icon} alt="" srcSet="" />
                                  <p className="m-0 text-white">Download</p>
                                </div>
                              </button> */}
                        {/* <button
                          className={`rounded col  ${
                            quotationDetail?.paymentStatus === "PAID"
                              ? "bg-green"
                              : quotationDetail?.paymentStatus === "NOT_PAID"
                              ? "bg-blue"
                              : "bg-EA4335"
                          }`}
                        >
                          <div className="d-flex gap-2 px-3 py-2 justify-content-center ">
                            <p className="m-0 text-white ">
                              {" "}
                              {quotationDetail?.paymentStatus === "PAID"
                                ? "Paid"
                                : quotationDetail?.quotationStatus ===
                                    "ACTIVE" &&
                                  quotationDetail?.paymentStatus === "NOT_PAID"
                                ? "Active"
                                : quotationDetail?.quotationStatus === "EXPIRED"
                                ? "Expired"
                                : ""}
                            </p>
                          </div>
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded mb-5">
                  <div className="p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4
                        style={{
                          fontSize: "29px",
                          fontWeight: "900",
                          color: "#212529",
                        }}
                      >
                        Quotation
                      </h4>
                      <img src={tlf_logo_quotation} alt="" srcSet="" />
                    </div>
                  </div>
                  <hr className="m-0 divider" />
                  <div className="p-3 mb-3">
                    <div className="row m-0">
                      <div className="col-md-4">
                        <p className=" m-0 w-700 fs-12 text-A7A7A7">TO</p>
                        <p
                          className=" m-0 w-700 fs-16 text-4E4E4E"
                          style={{ fontWeight: "700 !important" }}
                        >
                          {quotationDetail?.user.name}
                        </p>
                        <p className="text-gold text-decoration-underline">
                          {quotationDetail?.user.email}
                        </p>
                        <p className="">
                          {quotationDetail?.user.role === "individual"
                            ? quotationDetail?.user?.individualDetail
                                ?.billingInformation?.address
                            : quotationDetail?.user?.businessDetail
                                ?.billingInformation?.address}
                        </p>
                        <p className="">
                          {" "}
                          {quotationDetail?.user &&
                            getPhoneNumber(quotationDetail?.user)}
                        </p>
                      </div>
                      <div className="col-md-5">
                        <p className=" m-0 w-700 fs-12 text-A7A7A7">FROM</p>
                        <p
                          className=" m-0 w-700 fs-16 text-4E4E4E"
                          style={{ fontWeight: "700 !important" }}
                        >
                          THE LUXURY FLEET Co., Ltd.
                        </p>
                        <p className="text-gold text-decoration-underline">
                          info@theluxuryfleet.com
                        </p>
                        <p className="">
                          Dai 2 Hayashi Building 1F/2F, Kitashinagawa 1-8-20,
                          Shinagawa-ku, Tokyo 140-0001 Japan
                        </p>
                        <p className="">(+81) 3-6822-8772</p>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex ">
                          <table>
                            <tr>
                              <td className="pb-0">
                                <p className="p-0 m-0 text-7C7B7B fw-400 fs-14 text-nowrap">
                                  Issue Date
                                </p>
                              </td>
                              <td className="pb-0">
                                <p className=" ms-3 mb-0 text-4E4E4E fw-400 fs-14 text-nowrap fw-400">
                                  {" "}
                                  {quotationDetail &&
                                    moment(quotationDetail?.issueDate).format(
                                      "MMMM D, YYYY"
                                    )}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td className="pb-0">
                                <p className="p-0 m-0 text-7C7B7B fw-400 fs-14 text-nowrap">
                                  Invoice No.
                                </p>
                              </td>
                              <td className="pb-0">
                                <p className=" ms-3 mb-0 text-4E4E4E fw-400 fs-14 text-nowrap  fw-400">
                                  {" "}
                                  {quotationDetail?.quotationID}
                                </p>
                              </td>
                            </tr>
                            {/* <tr>
                                      <td className="p-0 text-7C7B7B fw-400 ">Tax No.</td>
                                      <td className="ps-5 px-0 py-0 pe-0 text-4E4E4E">
                                        T2010401114998
                                      </td>
                                    </tr> */}
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="row m-0 mt-3 mt-md-0">
                      <div className="col-md-4">
                        <table className="w-100 border-282828">
                          <tr className="border-282828">
                            <td
                              rowSpan={2}
                              className="text-center border-282828"
                            >
                              <p className="mb-2 fs-14 fw-400">VALID UNTIL</p>
                              <p className="mb-0 fs-14 fw-700 text-282828">
                                {quotationDetail &&
                                  moment(quotationDetail?.expiredDate).format(
                                    "MMMM D, YYYY"
                                  )}
                              </p>
                            </td>
                            <td className="text-center border-282828 bg-dark">
                              <p className="m-0 text-white fw-700 fs-14">
                                TOTAL
                              </p>
                            </td>
                          </tr>
                          <tr className="border-282828">
                            <td className="text-center border-282828">
                              <p className="m-0 text-282828 fw-700 fs-20">
                              {quotationDetail?.bookings
                              ? `${quotationDetail.bookings
                                  .reduce((grandTotal, item) => {
                                    const subtotal = item.bookingItems?.reduce(
                                      (subtotal, b) =>
                                        subtotal + b.price * b.qty,
                                      0
                                    );
                                    return grandTotal + (subtotal || 0);
                                  }, 0)
                                  .toLocaleString()} JPY`
                              : "0 JPY"}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className=" d-none d-md-block">
                    <table className="table table-quotation-items mb-3">
                      <tr className="bg-dark">
                        <th className="align-middle py-2">
                          <b className="pb-0 ms-2">Name</b>
                          <p className="py-0 ms-2 mb-0 text-white">
                            Description
                          </p>
                        </th>
                        <th>Service Date</th>
                        <th>Unit Price</th>
                        <th>Qty</th>
                        <th>Amount</th>
                      </tr>
                      {quotationDetail?.bookings.map((item, index) => (
                        <>
                          <tr className="bg-grey-light border border-bottom">
                            <td colSpan={5}>
                              <p className="m-0 ">
                                {getBookingType(
                                  item?.bookingType,
                                  item?.isAirportTransfer
                                )}
                              </p>
                            </td>
                          </tr>

                          {item?.bookingItems.map((b, bIndex) => (
                            <tr className="border-bottom" key={`aa-${bIndex}`}>
                              <td>
                                <b>{b.name}</b>
                                <p className="py-0 mb-0">{b.desc}</p>
                              </td>
                              <td>{b.serviceDate}</td>
                              <td>{`${b.price.toLocaleString()} JPY`}</td>
                              <td>{b.qty}</td>
                              <td>{`${(
                                b.price * b.qty
                              ).toLocaleString()} JPY`}</td>
                            </tr>
                          ))}

                          <tr className="bg-DADADA">
                            <td className="text-end pe-2" colSpan={4}>
                              SUBTOTAL
                            </td>
                            <td>
                              <b className="m-0 p-0">
                              {item?.bookingItems
                                  ? `${item.bookingItems
                                      .reduce(
                                        (subtotal, b) =>
                                          subtotal + b.price * b.qty,
                                        0
                                      )
                                      .toLocaleString()} JPY`
                                  : "0 JPY"}
                              </b>
                            </td>
                          </tr>
                        </>
                      ))}

                      <tr className="bg-dark">
                        <td colSpan={4} className="text-end">
                          <b>TOTAL</b>
                          <p className="py-0 my-0 text-white">（Tax Incl.)</p>
                        </td>
                        <td className="align-top">
                          <b className="m-0 p-0 fs-18">
                          {quotationDetail?.bookings
                              ? `${quotationDetail.bookings
                                  .reduce((grandTotal, item) => {
                                    const subtotal = item.bookingItems?.reduce(
                                      (subtotal, b) =>
                                        subtotal + b.price * b.qty,
                                      0
                                    );
                                    return grandTotal + (subtotal || 0);
                                  }, 0)
                                  .toLocaleString()} JPY`
                              : "0 JPY"}
                          </b>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div className="d-block d-md-none">
                    <div className="d-flex bg-dark justify-content-between align-items-center p-3">
                      <div className="">
                        <b className="pb-0  text-white">Name</b>
                        <p className="py-0  mb-0 text-white">Description</p>
                      </div>
                      <p className="mb-0 text-white">Amount</p>
                    </div>
                    {quotationDetail?.bookings.map((item, index) => (
                      <>
                        <div className="bg-grey-light p-3">
                          <p className="m-0 ">
                            {getBookingType(
                              item?.bookingType,
                              item?.isAirportTransfer
                            )}
                          </p>
                        </div>
                        {item?.bookingItems.map((b, bIndex) => {
                          return (
                            <div className="p-3 border-bottom">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="">
                                  <b>{b.name}</b>
                                  <p className="py-0 mb-0">{b.desc}</p>
                                </div>
                                {!openItems.includes(index + "" + bIndex) && (
                                  <p className="m-0 p-0 text-nowrap">
                                    {`${item.totalPrice.toLocaleString()} JPY`}
                                  </p>
                                )}
                              </div>
                              {openItems.includes(index + "" + bIndex) && (
                                <>
                                  <div className="border-bottom border-top mt-2">
                                    <div className="py-2">
                                      <div className="d-flex justify-content-between">
                                        <p className="m-0 fs-13">
                                          Service Date
                                        </p>
                                        <p className="m-0 fs-13">
                                          {b.serviceDate}
                                        </p>
                                      </div>
                                      <div className="d-flex justify-content-between mt-2">
                                        <p className="m-0 fs-13">Unit Price</p>
                                        <p className="m-0 fs-13">
                                          {`${b.price.toLocaleString()} JPY`}
                                        </p>
                                      </div>
                                      <div className="d-flex justify-content-between mt-2">
                                        <p className="m-0 fs-13">Qty</p>
                                        <p className="m-0 fs-13">{b.qty}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between mt-2">
                                    <b>Amount</b>
                                    <b>{`${(
                                      b.price * b.qty
                                    ).toLocaleString()} JPY`}</b>
                                  </div>
                                </>
                              )}
                              <div className="text-center">
                                {openItems.includes(index + bIndex) ? (
                                  <img
                                    src={collapse_up_icon}
                                    onClick={() =>
                                      toggleOpen(index + "" + bIndex)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={collapse_down_icon}
                                    onClick={() =>
                                      toggleOpen(index + "" + bIndex)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}
                        <div className="bg-DADADA p-3 ">
                          <div className="d-flex justify-content-between">
                            <p className="m-0">SUBTOTAL</p>
                            <b className="m-0 p-0">
                            {item?.bookingItems
                                  ? `${item.bookingItems
                                      .reduce(
                                        (subtotal, b) =>
                                          subtotal + b.price * b.qty,
                                        0
                                      )
                                      .toLocaleString()} JPY`
                                  : "0 JPY"}
                            </b>
                          </div>
                        </div>
                      </>
                    ))}
                    <div className="bg-dark text-white p-3 ">
                          <div className="d-flex justify-content-between">
                            <p className="m-0 text-white">TOTAL</p>
                            <b className="m-0 p-0">
                            {quotationDetail?.bookings
                              ? `${quotationDetail.bookings.reduce((grandTotal, item) => {
                                  const subtotal = item.bookingItems?.reduce(
                                    (subtotal, b) => subtotal + b.price * b.qty,
                                    0
                                  );
                                  return grandTotal + (subtotal || 0);
                                }, 0).toLocaleString()} JPY`
                              : '0 JPY'}
                            </b>
                          </div>
                      </div>
                  </div>

                  <div className="p-3">
                    {quotationDetail?.bookings.map((item, index) => (
                    <>
                      {item?.message?.length > 0 ? (
                        <>
                          {index === 0 && (
                            <p className="noto" style={{ fontWeight: "700" }}>
                              Notes
                            </p>
                          )}
                          <p className="fs-14 fw-700 m-0 text-#4E4E4E">
                            {getBookingType(item?.bookingType)} (
                            {
                              item?.bookingItems.find(
                                (item) => item.type === "vehicle"
                              )?.serviceDate
                            }
                            )
                          </p>
                          {item?.message ? (
                            item.message.map((m) => <p>{m.note}</p>)
                          ) : (
                            <p>-</p>
                          )}{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  </div>
                </div>
                <div className="pb-5"></div>
              </div>
            )}
          </div>
        </main>
      </Layout>
    </>
  );
};

export default QuotationDownloadPage;
