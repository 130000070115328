import DatePicker from "react-datepicker";
const AdminInputTimePicker = ({
  prefixIcon = null,
  suffixIcon = null,
  placeholder = "",
  mb = "mb-3",
  onSelectDate,
  label = null,
  value
}) => {
  return (
    <>
      <div className={mb}>
        {label && <label className="mb-1">{label}</label>}
        <div className="w-100 rounded border">
          <div className="d-flex gap-2 input-container">
            {prefixIcon && (
              <img src={prefixIcon} alt="" srcSet="" className="ms-3" />
            )}

            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              className={`input-field w-100 rounded border-0 py-2 ${
                prefixIcon != null ? "" : "px-3"
              }`}
              placeholderText={placeholder}
              onChange={(date) => {
                onSelectDate(date);
              }}
              selected = {value}
            />
            {suffixIcon && (
              <img src={suffixIcon} alt="" srcSet="" className="me-3" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminInputTimePicker;
