/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "../../layout/Layout";
import back_icon from "../../../icons/dashboard/back_icon.svg";
import add_gold_icon from "../../../icons/dashboard/add_gold.svg";
import download_icon from "../../../icons/dashboard/download_icon.svg";
import arrow_outward_icon from "../../../icons/dashboard/arrow_outward_icon.svg";
import date_icon from "../../../icons/dashboard/date_icon.svg";
import eye_gold_icon from "../../../icons/dashboard/eye_gold_icon.svg";
import edit_gold_icon from "../../../icons/dashboard/edit_gold_icon.svg";
import copy_gold from "../../../icons/dashboard/copy_gold.svg";
import gold_user_icon from "../../../icons/dashboard/gold_user_icon.svg";
import trash_icon from "../../../icons/dashboard/trash-icon.svg";
import arrow_bottom_select_icon from "../../../icons/dashboard/arrow-bottom-select-icon.svg";
import add_black_icon from "../../../icons/dashboard/add_black_icon.svg";
import selected_radio_icon from "../../../images/selected-radio.svg";
import unselected_radio_icon from "../../../images/un-selected-radio.svg";
import AdminInput from "../components/AdminInput";
import { useState, useEffect, Fragment } from "react";
import Select, { components } from "react-select";
import plus_icon from "../../../icons/plus.svg";
import input_delete_x_icon from "../../../icons/input-delete-x-icon.svg";
import {
  fetchClients,
  getDetails,
  formatTravelDate,
  formatTime,
  getBookingType,
  deleteTransfer,
  activeQuotation,
  formatPaxData,
  addUser,
  formatLuggageData,
  formatChildSeatData,
  getMeetAssistNames,
  duplicateBooking,
} from "../../../actions/admin/quotation";

import { useParams, useHistory } from "react-router-dom";
import AdminInputDatePicker from "../components/AdminInputDatePicker";
import moment from "moment";
import { Modal } from "react-bootstrap";
import AdminModal from "../components/AdminModal";
const QuotationDetail = () => {
  const [errorText, setErrorText] = useState("");
  const history = useHistory();
  var { quotationId } = useParams();

  const [quotationDetail, setQuotationDetail] = useState(null);
  const [notes, setNotes] = useState("");

  const handleNotes = (value) => {
    setNotes(value);
  };

  useEffect(() => {
    if (quotationId) {
      handleGetQuotationDetails(quotationId);
    } else {
      localStorage.removeItem("quotationData");
    }

    if (localStorage.getItem("editTransferData")) {
      localStorage.removeItem("editTransferData");
    }
  }, []);

  const customStylesSelect = {
    control: (provided) => ({
      ...provided,
      padding: "2px",
      marginTop: "10px",
      // Padding untuk keseluruhan kontrol
    }),
    // menu: (provided) => ({
    //   ...provided,
    //   padding: '10px', // Padding untuk menu dropdown
    // }),
    // option: (provided, state) => ({
    //   ...provided,
    //   padding: '10px', // Padding untuk setiap opsi
    //   backgroundColor: state.isFocused ? '#f0f0f0' : '#fff', // Gaya fokus
    // }),
  };

  const handleGetQuotationDetails = async (quotationId) => {
    try {
      var res = await getDetails(quotationId);
      setQuotationDetail(res);
      console.log(res.quotationStatus);
      if (res.quotationStatus !== "UNSAVED") {
        var selectedClient = res.user;
        var quotationInformation = {
          quotationId: res.quotationID,
          bookingReference: res.quotationNumber,
          issueDate: moment(res.issueDate).format("YYYY-MM-DD"),
          validUntil: moment(res.expiredDate).format("YYYY-MM-DD"),
        };
        var notes = res.notes ?? "";

        const dataToSave = {
          quotationInformation,
          quotationId,
          selectedClient,
          notes,
        };
        localStorage.setItem("quotationData", JSON.stringify(dataToSave));

        setQuotationInformation(quotationInformation);

        setFromBillingInformationForm({
          selectedClient: selectedClient,
          clientInputText: selectedClient?.name,
        });
        setNotes(notes);
      }
    } catch (error) {
      console.error("Error fetching quotation details:", error);
    }
  };

  const [clients, setClients] = useState([]);

  const [loadingFetchClients, setLoadingFetchClients] = useState(false);

  const [fromBillingInformationForm, setFromBillingInformationForm] = useState({
    selectedClient: null,
    clientInputText: "",
  });

  const getClients = async (query) => {
    setLoadingFetchClients(true);
    try {
      const response = await fetchClients(query);
      if (response?.data.data) {
        setClients(response?.data.data.data);
      }
    } catch (error) {
      console.error("Error in getClients:", error);
    } finally {
      setLoadingFetchClients(false);
    }
  };

  useEffect(() => {
    getClients("");
  }, []);

  const handleSelectClient = (client) => {
    setFromBillingInformationForm({
      clientInputText: client.name,
      selectedClient: client,
    });
  };

  const handleSearchClient = (e) => {
    setFromBillingInformationForm({
      selectedClient: null,
      clientInputText: e.target.value,
    });
    getClients(e.target.value);
  };

  const getPhoneNumber = (item) => {
    if (item.role === "individual") {
      return `(${
        item.individualDetail.billingInformation.prefix_number ?? "-"
      })${item.individualDetail.billingInformation.phoneNumber}`;
    }
    if (item.role === "business") {
      return `(${item.businessDetail.billingInformation.prefix_number ?? "-"})${
        item.businessDetail.billingInformation.phoneNumber
      }`;
    }
    return "";
  };

  const [quotationInformation, setQuotationInformation] = useState({
    quotationId: "",
    bookingReference: "",
    issueDate: moment().format("YYYY-MM-DD"),
    validUntil: moment().format("YYYY-MM-DD"),
  });

  const handleQuotationInformationChange = (e) =>
    setQuotationInformation({
      ...quotationInformation,
      [e.target.name]: e.target.value,
    });

  const handleIssueDateQuotationInformationChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setQuotationInformation({
      ...quotationInformation,
      issueDate: formattedDate,
    });
  };
  const handleValidUntilQuotationInformationChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setQuotationInformation({
      ...quotationInformation,
      validUntil: formattedDate,
    });
  };

  const handleDeleteTransfer = async (quotationId, bookingId) => {
    try {
      var res = await deleteTransfer(quotationId, bookingId);
      // setQuotationDetail(res);
      handleGetQuotationDetails(quotationId);
      console.log(res);
      closeModalDeleteTransferConfirmation();
    } catch (error) {
      console.error("Error fetching quotation details:", error);
    }
  };

  const handleNavigateToTransfer = () => {
    if (quotationId) {
      history.push("/admin/quotations/create/" + quotationId + "/transfer", {
        quotationInformation,
      });
    } else {
      history.push("/admin/quotations/create/transfer");
    }
  };

  const [errorModal, setErrorModal] = useState(false);

  const closeErrorModal = () => setErrorModal(false);

  const openErrorModal = () => setErrorModal(true);

  const [modalSubmitConfirmation, setModalSubmitConfirmation] = useState(false);

  const openModalSubmitConfirmation = () => {
    if (
      !quotationInformation.quotationId ||
      !quotationInformation.issueDate ||
      !quotationInformation.validUntil ||
      !fromBillingInformationForm?.selectedClient
    ) {
      alert("Please fill all fields");
      return;
    }
    setModalSubmitConfirmation(true);
  };
  const closeModalSubmitConfirmation = () => setModalSubmitConfirmation(false);

  const handleActiveQuotation = async () => {
    try {
      var res = await activeQuotation(
        quotationId,
        quotationInformation,
        fromBillingInformationForm.selectedClient,
        notes
      );
      console.log(res.data.id);
      localStorage.removeItem("quotationData");
      history.push("/admin/quotations");
    } catch (error) {
      closeModalSubmitConfirmation();
      setErrorText(error?.message);
      openErrorModal();
    }
  };

  const [modalDeleteTransferConfirmation, setModalDeleteTransferConfirmation] =
    useState(false);

  const [deleteTransferId, setDeleteTransferId] = useState(null);

  const openModalDeleteTransferConfirmation = (id) => {
    setDeleteTransferId(id);
    setModalDeleteTransferConfirmation(true);
  };

  const closeModalDeleteTransferConfirmation = () =>
    setModalDeleteTransferConfirmation(false);

  const [modalAddClientVisible, setModalAddClient] = useState(false);

  const openModalAddClient = () => setModalAddClient(true);
  const closeModalAddClient = () => setModalAddClient(false);
  const PRONOUNCE = [
    {
      label: "Mr.",
      value: "Mr.",
    },
    {
      label: "Ms.",
      value: "Ms.",
    },
    {
      label: "Mrs.",
      value: "Mrs.",
    },
    {
      label: "Mr./Ms.",
      value: "Mr./Ms.",
    },
  ];

  const [searchPhoneCode, setSearchPhoneCode] = useState(null);
  const [addUserForm, setAddUserForm] = useState({
    type: "individual",
    honorific: "Mr.",
    name: "",
    email: "",
    prefixNumber: "+81",
    phoneNumber: "",
    country: "Japan",
    countryCode: "JP",
    postalCode: "",
    billingAddress: "",
  });

  const handleAddUserChangeType = (type) => {
    if (type !== addUserForm.type) {
      setAddUserForm({ ...addUserForm, type: type, honorific: "" });
    }
  };
  const handlePronounceAddUser = (selectedHonorific) => {
    setAddUserForm({ ...addUserForm, honorific: selectedHonorific.value });
  };

  const handleAddUserChangeName = (e) => {
    setAddUserForm({ ...addUserForm, name: e.target.value });
  };

  const handleAddUserChangeEmail = (e) => {
    setAddUserForm({ ...addUserForm, email: e.target.value });
  };

  const handleAddUserChangePhoneNumber = (e) => {
    setAddUserForm({ ...addUserForm, phoneNumber: e.target.value });
  };

  const handleAddUserChangePostalCode = (e) => {
    setAddUserForm({ ...addUserForm, postalCode: e.target.value });
  };

  const handleAddUserChangeBillingAddress = (e) => {
    setAddUserForm({ ...addUserForm, billingAddress: e.target.value });
  };

  const handleAddUserChangeCountryCode = (dialCode, countryCode, name) => {
    setAddUserForm({
      ...addUserForm,
      country: name,
      countryCode: countryCode,
      prefixNumber: dialCode,
    });
  };

  const [countriesData, setCountriesData] = useState([]);
  const loadCountriesData = () => {
    fetch("/json/countries.json") // Replace with the actual path to your JSON file
      .then((response) => response.json())
      .then((data) => setCountriesData(data))
      .catch((error) => console.error("Error loading JSON data:", error));
  };
  useEffect(() => {
    loadCountriesData();
  }, []);

  const submitAddUser = async () => {
    try {
      const response = await addUser(addUserForm);
      console.log(response);
      alert("Success Add Client!");
      setAddUserForm({
        type: "individual",
        honorific: "Mr.",
        name: "",
        email: "",
        prefixNumber: "+81",
        phoneNumber: "",
        country: "Japan",
        countryCode: "JP",
        postalCode: "",
        billingAddress: "",
      });
      closeModalAddClient();
      setFromBillingInformationForm({
        selectedClient: response.data.user,
        clientInputText: response.data.user.name,
      });
    } catch (e) {
      console.error(e);
      const errorMessage =
        e.response?.data?.errors?.msg || "Failed to add client!";

      setErrorText(errorMessage);

      openErrorModal();
    }
  };

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("quotationData"));
    if (savedData) {
      setQuotationInformation(savedData.quotationInformation);
      quotationId = savedData.quotationId;
      setFromBillingInformationForm({
        selectedClient: savedData?.selectedClient,
        clientInputText: savedData?.selectedClient?.name,
      });
      setNotes(savedData?.notes);
    }
  }, []);

  useEffect(() => {
    var selectedClient = fromBillingInformationForm.selectedClient;
    const dataToSave = {
      quotationInformation,
      quotationId,
      selectedClient,
      notes,
    };
    localStorage.setItem("quotationData", JSON.stringify(dataToSave));
  }, [quotationInformation, quotationId, fromBillingInformationForm, notes]);

  const [modalDetailTransfer, setModalDetailTransfer] = useState(false);

  const [detailTransfer, setDetailTransfer] = useState(null);

  const openModalDetailTransfer = (data) => {
    console.log(data);
    setDetailTransfer(data);
    setModalDetailTransfer(true);
  };

  const closeModalDetailTransfer = () => setModalDetailTransfer(false);

  const [modalDuplicate, setModalDuplicate] = useState(false);

  const [duplicateTransferId, setDuplicateTransferId] = useState(null);

  const [loadingDuplicate, setLoadingDuplicate] = useState(false);

  const [recurringTravelDates, setRecurringTravelDates] = useState([""]);
  const handleSelectTravelDate = (date, index) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    console.log(formattedDate);
    setRecurringTravelDates((prevDates) => {
      if (prevDates[index] !== undefined) {
        const updatedDates = [...prevDates];
        updatedDates[index] = formattedDate;
        return updatedDates;
      }
      return prevDates;
    });
  };
  const addReccuringTravelDate = () => {
    setRecurringTravelDates([...recurringTravelDates, ""]);
  };

  const removeReccuringTravelDate = (index) => {
    if (recurringTravelDates.length > 1) {
      setRecurringTravelDates((recurringTravelDates) => [
        ...recurringTravelDates.slice(0, index),
        ...recurringTravelDates.slice(index + 1),
      ]);
    }
  };

  const openModalDuplicate = (id) => {
    setRecurringTravelDates([""]);
    setDuplicateTransferId(id);
    setModalDuplicate(true);
  };

  const closeModalDuplicate = () => setModalDuplicate(false);

  const handleDuplicateTransfer = async () => {
    try {
      setLoadingDuplicate(true);
      const filteredTravelDates = recurringTravelDates.filter(
        (date) => date.trim() !== ""
      );
      if (filteredTravelDates.length > 0) {
        var resDuplicate = await duplicateBooking({
          quotationId: quotationId,
          bookingId: duplicateTransferId,
          duplicateDate: filteredTravelDates,
        });
        await handleGetQuotationDetails(quotationId);
        closeModalDuplicate();
      }
    } catch (error) {
      alert("Failed to duplicate");
    } finally {
      setLoadingDuplicate(false);
    }
  };

  const handleNavigateToEditTransfer = async (item) => {
    localStorage.setItem("editTransferData", JSON.stringify(item));
    handleNavigateToTransfer();
  };

  return (
    <>
      <Layout>
        {/* <div class="fixed-top">
          <div className="col-md-9 ms-sm-auto col-lg-10 px-md-4 mt-3">
            <div className="alert alert-danger" role="alert">
              <strong>Alert Heading</strong> Some Word
            </div>
          </div>
        </div> */}
        <main
          className="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-4"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div id="header" className="mb-3">
            <div
              className="d-inline-flex align-items-center gap-2 pointer"
              onClick={() => history.goBack()}
            >
              <img
                src={back_icon}
                alt=""
                srcSet=""
                width={"10px"}
                height={"10px"}
              />
              <p className="noto m-0">Back </p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="mt-2">Quotation</h2>
              <div className="d-flex align-items-center justify-content-end gap-2">
                <div className="col-auto">
                  <button
                    className="btn btn-dark rounded px-3"
                    onClick={() =>
                      history.push(`/admin/quotations/download/${quotationId}`)
                    }
                  >
                    <div className="d-flex align-items-center gap-2 fs-14">
                      <p className="m-0 text-white pointer">
                        Download as Quotation
                      </p>
                      <img src={download_icon} alt="" srcSet="" />
                    </div>
                  </button>
                </div>
                {/* <div className="col-auto">
                  <button className="btn btn-dark rounded px-3" onClick={()=> history.push(`/admin/quotations/download/${quotationId}`)}>
                    <div className="d-flex align-items-center gap-2 fs-14">
                      <p className="m-0 text-white pointer">
                        Download as Invoice
                      </p>
                      <img src={download_icon} alt="" srcSet="" />
                    </div>
                  </button>
                </div> */}
                <div className="col-auto">
                  <button
                    className="btn btn-primary rounded px-3"
                    disabled={
                      quotationId == null ||
                      quotationDetail?.quotationStatus === "UNSAVED"
                        ? true
                        : false
                    }
                    onClick={() => history.push("/quotation/" + quotationId)}
                  >
                    <div className="d-flex align-items-center gap-2 fs-14">
                      <p className="m-0 text-white pointer">Quotation Link</p>
                      <img src={arrow_outward_icon} alt="" srcSet="" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="body">
            <div className="accordion mb-4" id="billingInformation">
              <div className="accordion-item">
                <h2 className="accordion-header" id="billingInformationLabel">
                  <button
                    className="accordion-button with-arrow-2 bg-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#billingInformationItem"
                    aria-expanded="true"
                    aria-controls="billingInformationItem"
                  >
                    Billing Information
                  </button>
                </h2>
                <div
                  id="billingInformationItem"
                  className="accordion-collapse collapse show"
                  aria-labelledby="billingInformationLabel"
                  data-bs-parent="#billingInformation"
                >
                  <div className="p-3">
                    <div className="row">
                      <div className="col-md-6">
                        <p htmlFor="" className="fs-12 mb-2 text-muted">
                          TO
                        </p>
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <AdminInput
                              suffixIcon={arrow_bottom_select_icon}
                              placeholder="Select Client"
                              mb="mb-1"
                              onChange={handleSearchClient}
                              value={fromBillingInformationForm.clientInputText}
                              disable={
                                quotationDetail?.paymentStatus === "PAID" ||
                                quotationDetail?.quotationStatus === "EXPIRED"
                              }
                            />
                            {fromBillingInformationForm.selectedClient ==
                              null && (
                              <div className="border rounded">
                                <div
                                  className="overflow-auto"
                                  style={{
                                    maxHeight: "205px",
                                    minHeight: "0px",
                                  }}
                                >
                                  {clients.map((item) => (
                                    <div
                                      className="border-bottom hover pointer"
                                      onClick={() => handleSelectClient(item)}
                                    >
                                      <div className="d-flex align-items-center gap-2 px-2 py-3">
                                        <img
                                          src={gold_user_icon}
                                          alt=""
                                          srcSet=""
                                        />
                                        <div className="">
                                          <p className=" fs-13 mb-0">
                                            {item.name}
                                          </p>
                                          <p className="fs-13 mb-1 text-8D8D8D">
                                            Mail : {item.email} | Tel.{" "}
                                            {getPhoneNumber(item)}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div
                                  className="bg-gold-FFF8E8 pointer px-2 py-3"
                                  onClick={openModalAddClient}
                                >
                                  <div className="d-flex align-items-center gap-2">
                                    <img
                                      src={add_black_icon}
                                      alt=""
                                      srcSet=""
                                    />
                                    <p className="m-0">Add New Client</p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {fromBillingInformationForm.selectedClient && (
                              <>
                                <div className="bg-gold-FFF8E8 rounded p-3 mt-2">
                                  <div className="d-flex justify-content-between align-items-start">
                                    <table cellPadding={15}>
                                      <tr>
                                        <td>Email</td>
                                        <td>
                                          {
                                            fromBillingInformationForm
                                              .selectedClient.email
                                          }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Billing Address</td>
                                        <td>
                                          {fromBillingInformationForm
                                            .selectedClient.role ===
                                          "individual"
                                            ? fromBillingInformationForm
                                                .selectedClient
                                                ?.individualDetail
                                                ?.billingInformation?.address
                                            : fromBillingInformationForm
                                                .selectedClient?.businessDetail
                                                ?.billingInformation?.address}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Mobile number/phone</td>
                                        <td>
                                          {getPhoneNumber(
                                            fromBillingInformationForm.selectedClient
                                          )}
                                        </td>
                                      </tr>
                                    </table>
                                    <button
                                      className="btn bg-gold rounded px-4"
                                      disabled={
                                        quotationDetail?.paymentStatus ===
                                          "PAID" ||
                                        quotationDetail?.quotationStatus ===
                                          "EXPIRED"
                                      }
                                      onClick={() => {
                                        setFromBillingInformationForm({
                                          selectedClient: null,
                                          clientInputText: "",
                                        });
                                      }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p htmlFor="" className="fs-12 mb-3  text-muted">
                          FROM
                        </p>
                        <b className="text-4E4E4E">
                          THE LUXURY FLEET Co., Ltd.
                        </b>
                        <div className="bg-F5F5F5 p-3 mt-4">
                          <div className="d-flex justify-content-between">
                            <div className="col">
                              <table className="table">
                                <tr>
                                  <td className="text-7C7B7B pe-4 align-top">
                                    Email
                                  </td>
                                  <td className="text-secondary">
                                    info@theluxuryfleet.com
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-7C7B7B pe-4 align-top">
                                    Tax No.
                                  </td>
                                  <td className="text-secondary">
                                    T2010401114998
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-7C7B7B pe-4 align-top">
                                    Address
                                  </td>
                                  <td className="text-secondary">
                                    Dai 2 Hayashi Building 1F/2F, Kitashinagawa
                                    1-8-20, Shinagawa-ku, Tokyo 140-0001 Japan
                                  </td>
                                </tr>

                                <tr>
                                  <td className="text-7C7B7B pe-4 align-top">
                                    Tel.
                                  </td>
                                  <td className="text-secondary">
                                    (+81) 3-6822-8772
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion mb-4" id="quotationformation">
              <div className="accordion-item ">
                <h2 className="accordion-header" id="quotationformationLabel">
                  <button
                    className="accordion-button with-arrow-2 bg-white px-3"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#quotationformationItem"
                    aria-expanded="true"
                    aria-controls="quotationformationItem"
                  >
                    Quotation Information
                  </button>
                </h2>
                <div
                  id="quotationformationItem"
                  className="accordion-collapse collapse show"
                  aria-labelledby="quotationformationLabel"
                  data-bs-parent="#quotationformation"
                >
                  <div className="p-3">
                    <div className="row">
                      <div className="col-md-3">
                        <label className="mb-2 fs-14">Quotation</label>
                        <AdminInput
                          placeholder="Enter Quotation ID"
                          value={quotationInformation.quotationId}
                          onChange={handleQuotationInformationChange}
                          name="quotationId"
                          disable={
                            quotationDetail?.paymentStatus === "PAID" ||
                            quotationDetail?.quotationStatus === "EXPIRED"
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="mb-2 fs-14">
                          Booking Reference No.
                          <span className="text-muted">(issued by client)</span>
                        </label>
                        <AdminInput
                          placeholder="Booking Reference No."
                          value={quotationInformation.bookingReference}
                          onChange={handleQuotationInformationChange}
                          name="bookingReference"
                          disable={
                            quotationDetail?.paymentStatus === "PAID" ||
                            quotationDetail?.quotationStatus === "EXPIRED"
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="mb-2 fs-14">Issue Date</label>
                        <AdminInputDatePicker
                          placeholder="Select Issue Date"
                          prefixIcon={date_icon}
                          onSelectDate={
                            handleIssueDateQuotationInformationChange
                          }
                          mb={""}
                          name="issueDate"
                          value={
                            quotationInformation.issueDate != null
                              ? moment(
                                  quotationInformation.issueDate,
                                  "YYYY-MM-DD"
                                ).toDate()
                              : null
                          }
                          disable={
                            quotationDetail?.paymentStatus === "PAID" ||
                            quotationDetail?.quotationStatus === "EXPIRED"
                          }
                        />
                      </div>
                      <div className="col-md-3">
                        <label className="mb-2 fs-14">Valid Date</label>
                        <AdminInputDatePicker
                          placeholder="Select Issue Date"
                          prefixIcon={date_icon}
                          onSelectDate={
                            handleValidUntilQuotationInformationChange
                          }
                          mb={""}
                          name="validUntil"
                          value={
                            quotationInformation.validUntil != null
                              ? moment(
                                  quotationInformation.validUntil,
                                  "YYYY-MM-DD"
                                ).toDate()
                              : moment().add(1, "month").format("YYYY-MM-DD")
                          }
                          disable={
                            quotationDetail?.paymentStatus === "PAID" ||
                            quotationDetail?.quotationStatus === "EXPIRED"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion mb-4" id="transferList">
              <div className="accordion-item">
                <h2 className="accordion-header" id="transferListLabel">
                  <button
                    className="accordion-button with-arrow-2 bg-white px-3"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#transferListItem"
                    aria-expanded="true"
                    aria-controls="transferListItem"
                  >
                    Transfer List
                  </button>
                </h2>
                <div
                  id="transferListItem"
                  className="accordion-collapse collapse show"
                  aria-labelledby="transferListLabel"
                  data-bs-parent="#transferList"
                >
                  <div className="p-3">
                    {quotationDetail?.bookings.map((item, index) => (
                      <div className="border rounded p-3 mb-2" key={index}>
                        <div className="row">
                          <div className="col-2">
                            <p className=" fs-11 text-A7A7A7 mb-1">
                              Travel Date
                            </p>
                            <p className="m-0 fs-14 text-4E4E4E">
                              {formatTravelDate(item.travelDate)}
                            </p>
                          </div>
                          <div className="col-2">
                            <p className=" fs-11 text-A7A7A7 mb-1">
                              Transfer Type
                            </p>
                            <p className="m-0 fs-14 text-4E4E4E">
                              {getBookingType(
                                item?.bookingType,
                                item?.isAirportTransfer
                              )}
                            </p>
                          </div>
                          <div className="col-2">
                            <p className=" fs-11 text-A7A7A7 mb-1">
                              Description
                            </p>
                            <p className="m-0 fs-14 text-4E4E4E">
                              {`${item?.pickupLocation.label.split(",")[0]} ⇒ ${
                                item?.destinations[
                                  item?.destinations.length - 1
                                ].label.split(",")[0]
                              }`}
                            </p>
                          </div>
                          <div className="col-2">
                            <p className=" fs-11 text-A7A7A7 mb-1">Vehicle</p>
                            <p className="m-0 fs-14 text-4E4E4E">
                              {item?.bookingItems
                                .filter((item) => item.type === "vehicle")
                                .reduce((acc, v) => {
                                  const existingItem = acc.find(
                                    (i) => i.name === v.name
                                  );

                                  if (existingItem) {
                                    // Jika ada, jumlahkan qty
                                    existingItem.qty += v.qty;
                                  } else {
                                    acc.push({ name: v.name, qty: v.qty });
                                  }
                                  return acc;
                                }, [])
                                .map((v) => (
                                  <>
                                    {v.name} x{v.qty}{" "}
                                  </>
                                ))}
                            </p>
                          </div>
                          <div className="col-4">
                            <div className="d-flex justify-content-end align-items-center gap-1 mt-2">
                              <button
                                className="btn btn-outline-default  rounded"
                                onClick={() => openModalDetailTransfer(item)}
                              >
                                <div className="d-flex">
                                  <img
                                    src={eye_gold_icon}
                                    alt=""
                                    srcSet=""
                                    className="me-2"
                                  />
                                  <p className="m-0">Detail</p>
                                </div>
                              </button>
                              <button
                                className="btn btn-outline-default  rounded"
                                disabled={
                                  quotationDetail?.paymentStatus === "PAID" ||
                                  quotationDetail?.quotationStatus === "EXPIRED"
                                }
                                onClick={() =>
                                  handleNavigateToEditTransfer(item)
                                }
                              >
                                <div className="d-flex">
                                  <img
                                    src={edit_gold_icon}
                                    alt=""
                                    srcSet=""
                                    className="me-2"
                                  />
                                  <p className="m-0">Edit </p>
                                </div>
                              </button>
                              <button
                                className="btn btn-outline-default  rounded"
                                disabled={
                                  quotationDetail?.paymentStatus === "PAID" ||
                                  quotationDetail?.quotationStatus === "EXPIRED"
                                }
                                onClick={() => openModalDuplicate(item._id)}
                              >
                                <div className="d-flex">
                                  <img
                                    src={copy_gold}
                                    alt=""
                                    srcSet=""
                                    className="me-2"
                                  />
                                  <p className="m-0">Duplicate</p>
                                </div>
                              </button>
                              <button
                                className="btn btn-danger rounded p-0"
                                style={{ width: "36px", height: "36px" }}
                                disabled={
                                  quotationDetail?.paymentStatus === "PAID" ||
                                  quotationDetail?.quotationStatus === "EXPIRED"
                                }
                                onClick={() =>
                                  openModalDeleteTransferConfirmation(item._id)
                                }
                                // handleDeleteTransfer(quotationId, item._id)
                              >
                                <img
                                  src={trash_icon}
                                  alt=""
                                  srcSet=""
                                  className=""
                                  style={{ width: "21px", height: "21px" }}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="border-dashsed rounded p-5 mt-3">
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn"
                          onClick={handleNavigateToTransfer}
                          disabled={
                            quotationDetail?.paymentStatus === "PAID" ||
                            quotationDetail?.quotationStatus === "EXPIRED"
                          }
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={add_gold_icon}
                              alt=""
                              srcSet=""
                              className="me-2"
                            />
                            <p className="fs-14 m-0">Add Transfer</p>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion mb-4" id="quotatItems">
              <div className="accordion-item">
                <h2 className="accordion-header" id="quotatItemsLabel">
                  <button
                    className="accordion-button with-arrow-2 bg-white px-3"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#quotatItemsItem"
                    aria-expanded="true"
                    aria-controls="quotatItemsItem"
                  >
                    Quotation Items
                  </button>
                </h2>
                <div
                  id="quotatItemsItem"
                  className="accordion-collapse collapse show"
                  aria-labelledby="quotatItemsLabel"
                  data-bs-parent="#quotatItems"
                >
                  <table className="table table-quotation-items">
                    <tr className="bg-dark">
                      <th className="align-middle py-2">
                        <b className="pb-0 ms-2">Name</b>
                        <p className="py-0 ms-2 text-white mb-0">Description</p>
                      </th>
                      <th>Service Date</th>
                      <th>Unit Price</th>
                      <th>Qty</th>
                      <th>Amount</th>
                    </tr>
                    <>
                      {quotationDetail?.bookings.map((item, index) => (
                        <>
                          {/* Booking Type Row */}
                          <tr className="bg-grey-light border border-bottom">
                            <td colSpan={5}>
                              <p className="m-0">
                                {getBookingType(
                                  item?.bookingType,
                                  item?.isAirportTransfer
                                )}
                              </p>
                            </td>
                          </tr>

                          {/* Booking Items */}
                          {item?.bookingItems.map((b, index) => (
                            <tr
                              key={index}
                              className={`border-bottom ${
                                index !== 0 ? "border-top-E4D6B3" : ""
                              }`}
                            >
                              <td>
                                <b>{b.name}</b>
                                <p className="py-0 mb-0">{b.desc}</p>
                              </td>
                              <td>{b.serviceDate}</td>
                              <td>{`${b.price.toLocaleString()} JPY`}</td>
                              <td>{b.qty}</td>
                              <td>{`${(
                                b.price * b.qty
                              ).toLocaleString()} JPY`}</td>
                            </tr>
                          ))}

                          {/* Subtotal */}
                          <tr className="bg-DADADA">
                            <td className="text-end pe-2" colSpan={4}>
                              SUBTOTAL
                            </td>
                            <td>
                              <b className="m-0 p-0">
                                {item?.bookingItems
                                  ? `${item.bookingItems
                                      .reduce(
                                        (subtotal, b) =>
                                          subtotal + b.price * b.qty,
                                        0
                                      )
                                      .toLocaleString()} JPY`
                                  : "0 JPY"}
                              </b>
                            </td>
                          </tr>
                        </>
                      ))}

                      {/* Total */}
                      <tr className="bg-dark text-white">
                        <td colSpan={4} className="text-end">
                          <b>TOTAL</b>
                          <p className="py-0 my-0 text-white">(Tax Incl.)</p>
                        </td>
                        <td className="align-top">
                          <b className="m-0 p-0 fs-18">
                            {quotationDetail?.bookings
                              ? `${quotationDetail.bookings
                                  .reduce((grandTotal, item) => {
                                    const subtotal = item.bookingItems?.reduce(
                                      (subtotal, b) =>
                                        subtotal + b.price * b.qty,
                                      0
                                    );
                                    return grandTotal + (subtotal || 0);
                                  }, 0)
                                  .toLocaleString()} JPY`
                              : "0 JPY"}
                          </b>
                        </td>
                      </tr>
                    </>
                  </table>
                </div>
              </div>
            </div>

            <div className="accordion mb-4" id="quotationNotes">
              <div className="accordion-item">
                <h2 className="accordion-header" id="quotationNotesLabel">
                  <button
                    className="accordion-button with-arrow-2 bg-white px-3"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#quotationNotesItem"
                    aria-expanded="true"
                    aria-controls="quotationNotesItem"
                  >
                    Notes
                  </button>
                </h2>
                <div
                  id="quotationNotesItem"
                  className="accordion-collapse collapse show"
                  aria-labelledby="quotationNotesLabel"
                  data-bs-parent="#quotationNotes"
                >
                  <div className="p-3">
                    <textarea
                      className="w-100 rounded border py-2 px-2"
                      name=""
                      id=""
                      rows="5"
                      placeholder="Itinerary details or additional notes"
                      onChange={(e) => handleNotes(e.target.value)}
                      value={notes}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            {quotationId && quotationDetail?.bookings.length > 0 && (
              <div className="d-grid">
                <button
                  className="btn btn-primary py-2"
                  onClick={openModalSubmitConfirmation}
                  disabled={
                    quotationDetail?.paymentStatus === "PAID" ||
                    quotationDetail?.quotationStatus === "EXPIRED"
                  }
                >
                  Save
                </button>
              </div>
            )}
          </div>

          <AdminModal
            show={modalAddClientVisible}
            title="Add Client"
            size="modal-lg"
            onClose={closeModalAddClient}
          >
            <div className="row mb-3">
              <div className="col-6">
                <div
                  className={`rounded w-100 ${
                    addUserForm.type === "individual"
                      ? "border-gold"
                      : "border-gold-100"
                  } p-3 pointer hover`}
                  onClick={() => handleAddUserChangeType("individual")}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <img
                      src={
                        addUserForm.type === "individual"
                          ? selected_radio_icon
                          : unselected_radio_icon
                      }
                      alt=""
                      srcSet=""
                    />
                    <p
                      className={`m-0 ${
                        addUserForm.type === "individual" ? "fw-bold" : ""
                      }`}
                    >
                      Individual
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div
                  className={`rounded w-100 ${
                    addUserForm.type === "business"
                      ? "border-gold"
                      : "border-gold-100"
                  } p-3 pointer hover`}
                  onClick={() => handleAddUserChangeType("business")}
                >
                  <div className="d-flex gap-2 align-items-center">
                    <img
                      src={
                        addUserForm.type === "business"
                          ? selected_radio_icon
                          : unselected_radio_icon
                      }
                      alt=""
                      srcSet=""
                    />
                    <p
                      className={`m-0 ${
                        addUserForm.type === "business" ? "fw-bold" : ""
                      }`}
                    >
                      Business
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* {addUserForm.type === "business" && ( */}
              <div className="col-3">
                <p className="mb-2">Honorifics</p>
                <Select
                  options={PRONOUNCE}
                  onChange={handlePronounceAddUser}
                  styles={customStylesSelect}
                />
              </div>
              {/* )} */}
              <div className="col">
                <label className="mb-2 fs-14">Name</label>
                <AdminInput
                  placeholder="Name"
                  value={addUserForm.name}
                  onChange={handleAddUserChangeName}
                  name="name"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <label className="mb-2 fs-14">Email</label>
                <AdminInput
                  placeholder="Email"
                  value={addUserForm.email}
                  onChange={handleAddUserChangeEmail}
                  name="email"
                  type="email"
                />
              </div>
              <div className="col-6">
                <label className="mb-2 fs-14">Phone No.</label>
                <div className="d-flex">
                  <button
                    className="btn bg-gold-light-100 fw-100 rounded noto dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={`https://flagsapi.com/${addUserForm.countryCode}/flat/24.png`}
                      alt=""
                    />{" "}
                    ({addUserForm.prefixNumber})
                  </button>
                  <ul className="dropdown-menu">
                    <div className="px-1 align-items-center mb-3 dropdown-search">
                      <input
                        placeholder={"Please type in your country name"}
                        type="text"
                        className={`form-control p-3 mb-2`}
                        defaultValue={searchPhoneCode}
                        onChange={(e) => setSearchPhoneCode(e.target.value)}
                      />
                    </div>
                    {searchPhoneCode !== null
                      ? countriesData
                          ?.filter((item) => {
                            const searchValue =
                              searchPhoneCode?.toLowerCase() || "";
                            return (
                              item.name.toLowerCase().includes(searchValue) ||
                              item.dial_code.toLowerCase().includes(searchValue)
                            );
                          })
                          ?.map((country, index) => (
                            <Fragment key={country.code}>
                              <li
                                className="dropdown-item m-0"
                                onClick={() =>
                                  handleAddUserChangeCountryCode(
                                    country.dial_code,
                                    country.code,
                                    country.name
                                  )
                                }
                              >
                                <div className="row px-1 align-items-center">
                                  <div className="col-auto">
                                    <div className="bg-secondary bg-opacity-25 px-1">
                                      <img
                                        src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                        alt={country.name}
                                      />
                                    </div>
                                  </div>
                                  <div className="col">
                                    ({country.dial_code}) {country.name}
                                  </div>
                                </div>
                              </li>
                              <hr className="my-1" />
                            </Fragment>
                          ))
                      : countriesData?.map((country, index) => (
                          <Fragment key={country.code}>
                            <li
                              className="dropdown-item m-0"
                              onClick={() =>
                                handleAddUserChangeCountryCode(
                                  country.dial_code,
                                  country.code,
                                  country.name
                                )
                              }
                            >
                              <div className="row px-1 align-items-center">
                                <div className="col-auto">
                                  <div className="bg-secondary bg-opacity-25 px-1">
                                    <img
                                      src={`https://flagsapi.com/${country.code}/flat/24.png`}
                                      alt={country.name}
                                    />
                                  </div>
                                </div>
                                <div className="col">
                                  ({country.dial_code}) {country.name}
                                </div>
                              </div>
                            </li>
                            <hr className="my-1" />
                          </Fragment>
                        ))}
                  </ul>
                  <AdminInput
                    placeholder="Phone No."
                    value={addUserForm.phoneNumber}
                    onChange={handleAddUserChangePhoneNumber}
                    name="phone"
                    mb="mb-0 w-100"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <label className="mb-2 fs-14">Country</label>
                <AdminInput
                  placeholder="Country"
                  value={addUserForm.country}
                  onChange={() => {}}
                  name="country"
                  disable={true}
                />
              </div>
              <div className="col-6">
                <label className="mb-2 fs-14">Postal Code</label>
                <AdminInput
                  placeholder="Postal Code"
                  value={addUserForm.postalCode}
                  onChange={handleAddUserChangePostalCode}
                  name="postalCode"
                />
              </div>
            </div>
            <label className="mb-2 fs-14">Billing Address</label>
            <AdminInput
              placeholder="Billing Address"
              value={addUserForm.billingAddress}
              onChange={handleAddUserChangeBillingAddress}
              name="billingAddress"
            />

            <div className="d-grid mt-3">
              <button
                className="btn rounded bg-gold py-2"
                onClick={submitAddUser}
                disabled={
                  addUserForm.name === "" ||
                  addUserForm.honorific === "" ||
                  addUserForm.email === "" ||
                  addUserForm.prefixNumber === "" ||
                  addUserForm.phoneNumber === "" ||
                  addUserForm.country === "" ||
                  addUserForm.countryCode === "" ||
                  addUserForm.postalCode === "" ||
                  addUserForm.billingAddress === ""
                }
              >
                <span className="text-white">Save </span>
              </button>
            </div>
          </AdminModal>
          <AdminModal
            show={errorModal}
            size="modal-md"
            centered={true}
            onClose={closeErrorModal}
            title={"ERROR"}
          >
            <h5 className="text-center text-EB001B">{errorText}</h5>
          </AdminModal>
          <AdminModal
            show={modalSubmitConfirmation}
            size="modal-md"
            centered={true}
            onClose={closeModalSubmitConfirmation}
          >
            <h5 className="text-center mb-3">
              Are you sure you want to submit this quotation?
            </h5>

            <div className="d-flex justify-content-center gap-2">
              <div className="col-3">
                <div className="d-grid">
                  <button
                    className="btn border-gold"
                    onClick={closeModalSubmitConfirmation}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-3">
                <div className="d-grid">
                  <button
                    className="btn bg-gold text-white"
                    onClick={handleActiveQuotation}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </AdminModal>
          <AdminModal
            show={modalDeleteTransferConfirmation}
            size="modal-md"
            centered={true}
            onClose={closeModalDeleteTransferConfirmation}
          >
            <h5 className="text-center mb-3">
              Are you sure you want to delete this transfer?
            </h5>
            <div className="d-flex justify-content-center gap-2">
              <div className="col-3">
                <div className="d-grid">
                  <button
                    className="btn border-gold"
                    onClick={closeModalDeleteTransferConfirmation}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-3">
                <div className="d-grid">
                  <button
                    className="btn bg-EA4335 text-white"
                    onClick={() =>
                      handleDeleteTransfer(quotationId, deleteTransferId)
                    }
                  >
                    <span className="text-white">Delete</span>
                  </button>
                </div>
              </div>
            </div>
          </AdminModal>

          <AdminModal
            show={modalDetailTransfer}
            size="modal-lg"
            title={"Transfer Details"}
            onClose={closeModalDetailTransfer}
          >
            {/* {JSON.stringify(detailTransfer)} */}
            <div className="row">
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">Item ID</p>
                <p>#{detailTransfer?.key}</p>
              </div>
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">Travel Date</p>
                <p>{formatTravelDate(detailTransfer?.travelDate)}</p>
              </div>
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">Transfer Type</p>
                <p>
                  {getBookingType(
                    detailTransfer?.bookingType,
                    detailTransfer?.isAirportTransfer
                  )}
                </p>
              </div>
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">Passenger’s Name</p>
                <p>
                  {detailTransfer?.passengerPronounced +
                    " " +
                    detailTransfer?.passengerName}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">Pick-up Time</p>
                <p>
                  {formatTravelDate(detailTransfer?.travelDate) +
                    ", " +
                    detailTransfer?.pickupTime}
                </p>
              </div>
              <div className="col-9">
                <p className="mb-0 fs-12 text-A7A7A7">Pick-up Location</p>
                <p>{detailTransfer?.pickupLocation?.formatted_address}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">
                  Estimated Drop-off Time
                </p>
                <p>
                  {formatTravelDate(detailTransfer?.customerDropoffDateTime) +
                    ", " +
                    formatTime(detailTransfer?.customerDropoffDateTime)}{" "}
                </p>
              </div>
              <div className="col-9">
                <p className="mb-0 fs-12 text-A7A7A7">Drop-off Location</p>
                <p>
                  {detailTransfer?.destinations?.map((item, index) => (
                    <>
                      ({index + 1}) {item.formatted_address}
                    </>
                  ))}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">Vehicle</p>
                <p>
                  {detailTransfer?.bookingItems
                    .filter((item) => item.type === "vehicle")
                    .reduce((acc, v) => {
                      const existingItem = acc.find((i) => i.name === v.name);

                      if (existingItem) {
                        // Jika ada, jumlahkan qty
                        existingItem.qty += v.qty;
                      } else {
                        acc.push({ name: v.name, qty: v.qty });
                      }
                      return acc;
                    }, [])
                    .map((v) => (
                      <>
                        {v.name} x{v.qty}{" "}
                      </>
                    ))}
                </p>
              </div>
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">Passengers</p>
                <p>{formatPaxData(detailTransfer?.pax)}</p>
              </div>
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">Luggage</p>
                <p>{formatLuggageData(detailTransfer?.luggage)}</p>
              </div>
              <div className="col-3">
                <p className="mb-0 fs-12 text-A7A7A7">Add-on Services</p>
                <p>
                  {formatChildSeatData(detailTransfer?.childSeat)} <br />
                  {getMeetAssistNames(detailTransfer?.bookingItems)}
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-6">
                <p className="mb-0 fs-12 text-A7A7A7">Message・Inquiry</p>
                <p>
                  {detailTransfer?.message?.length > 0
                    ? detailTransfer.message[0]?.note
                    : "-"}
                </p>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-4">
                <div className="d-grid">
                  <button
                    className="btn btn-outline-default  rounded"
                    onClick={() => {
                      closeModalDetailTransfer();
                      handleNavigateToEditTransfer(detailTransfer);
                    }}
                    disabled={
                      quotationDetail?.paymentStatus === "PAID" ||
                      quotationDetail?.quotationStatus === "EXPIRED"
                    }
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src={edit_gold_icon}
                        alt=""
                        srcSet=""
                        className="me-2"
                      />
                      <p className="m-0">Edit</p>
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-4">
                <div className="d-grid">
                  <button
                    className="btn btn-outline-default  rounded"
                    disabled={
                      quotationDetail?.paymentStatus === "PAID" ||
                      quotationDetail?.quotationStatus === "EXPIRED"
                    }
                    onClick={() => {
                      closeModalDetailTransfer();
                      openModalDuplicate(detailTransfer._id);
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <img src={copy_gold} alt="" srcSet="" className="me-2" />
                      <p className="m-0">Duplicate Transfer</p>
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-4">
                <div className="d-grid">
                  <button
                    className="btn bg-EA4335  rounded"
                    onClick={() => {
                      closeModalDetailTransfer();
                      openModalDeleteTransferConfirmation(detailTransfer._id);
                    }}
                    disabled={
                      quotationDetail?.paymentStatus === "PAID" ||
                      quotationDetail?.quotationStatus === "EXPIRED"
                    }
                  >
                    <div className="d-flex justify-content-center">
                      <img src={trash_icon} alt="" srcSet="" className="me-2" />
                      <p className="m-0 text-white">Delete Transfer</p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </AdminModal>

          <AdminModal
            show={modalDuplicate}
            size="modal-lg"
            centered={false}
            onClose={closeModalDuplicate}
            title={"Duplicate Transfer"}
          >
            <>
              <div className="row mb-3">
                {recurringTravelDates.map((item, index) => (
                  <div className="col-6 mb-2 px-1">
                    <AdminInputDatePicker
                      placeholder="Select Issue Date"
                      prefixIcon={date_icon}
                      onSelectDate={handleSelectTravelDate}
                      mb={""}
                      name="issueDate"
                      hasIndex={true}
                      index={index}
                      value={
                        recurringTravelDates[index] != null
                          ? moment(
                              recurringTravelDates[index],
                              "YYYY-MM-DD"
                            ).toDate()
                          : null
                      }
                      suffixIcon={index !== 0 && input_delete_x_icon}
                      onSufixIconClick={() => removeReccuringTravelDate(index)}
                    />
                  </div>
                ))}
                <div className="col-auto px-1">
                  <div className="d-grid">
                    <button
                      className="bg-gold-light-100 px-4 rounded"
                      style={{ height: "40px" }}
                      onClick={addReccuringTravelDate}
                    >
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <img src={plus_icon} alt="" srcSet="" />
                        <p className="m-0">Add Date</p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-4 px-1">
                  <div className="d-grid">
                    <button
                      className="btn border-gold rounded"
                      onClick={closeModalDuplicate}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="col-4 px-1">
                  <div className="d-grid">
                    <button
                      disabled={loadingDuplicate}
                      className="btn bg-gold rounded"
                      onClick={handleDuplicateTransfer}
                    >
                      <span className="text-white">
                        {loadingDuplicate ? "Loading.." : "Duplicate"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AdminModal>
        </main>
      </Layout>
    </>
  );
};

export default QuotationDetail;
