import { useTranslation } from "react-i18next";

const TNCTables = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <p className="mb-2 fw-bold">{t("TNC.General")}</p>
      <ul>
        <li className="text-4E4E4E mb-1">
        {t("TNC.General1")}
        </li>
        <li className="text-4E4E4E mb-1">
         
        {t("TNC.General2")}
        </li>
        <li className="text-4E4E4E mb-1">
          
        {t("TNC.General3")}
        </li>
        <li className="text-4E4E4E mb-1">
         
        {t("TNC.General4")}
        </li>
        <li className="text-4E4E4E mb-1">
         
        {t("TNC.General5")}
        </li>
        <li className="text-4E4E4E mb-1">
         
        {t("TNC.General6")}
        </li>
        <li className="text-4E4E4E mb-1">
         
        {t("TNC.General7")}
        </li>
        <li className="text-4E4E4E mb-1">
         
        {t("TNC.General8")}
        </li>
      </ul>
      <p className="mb-2 fw-bold">{t("TNC.Regarding.Airport.Transfers")}</p>
      <ul>
        <li className="text-4E4E4E mb-1">
        {t("TNC.Regarding.Airport.Transfers1")}
        </li>
        <li className="text-4E4E4E mb-1">
        {t("TNC.Regarding.Airport.Transfers2")}
        </li>
        <li className="text-4E4E4E mb-1">
        
        {t("TNC.Regarding.Airport.Transfers3")}
        </li>
        <li className="text-4E4E4E mb-1">
         
        {t("TNC.Regarding.Airport.Transfers4")}
        </li>
      </ul>
      <p className="mb-2 fw-bold">{t("TNC.Cancellation.Policy")}</p>
      <div className="d-none d-md-block">
        <table className="w-100 mb-4" id="cancellationTable">
          <tr>
            <td colSpan={6} className="bg-gold-light2">
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R2.T1") }}
              ></p>
            </td>
          </tr>
          <tr style={{ borderBottom: "4px solid #56273A" }}>
            <td colSpan={2} className="bg-56273A">
              <p className="fw-700 m-0 text-gold">{t("Translation.R1.T1")}</p>
            </td>
            <td className="bg-F1F1F2">
              <p className="fw-700 m-0">{t("Translation.R1.T2")}</p>
            </td>
            <td className="bg-E6E7E8">
              <p className="fw-700 m-0">{t("Translation.R1.T3")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R1.T3.sp"),
                }}
              ></p>
            </td>
            <td className="bg-DCDDDE">
              <p className="fw-700 m-0 ">{t("Translation.R1.T4")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R1.T4.sp"),
                }}
              ></p>
            </td>
            <td className="bg-D1D3D4">
              <p
                className="fw-700 m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R1.T5") }}
              ></p>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="bg-E0D8DA">
              <p className="fw-700 m-0">{t("Translation.R3.T1")}</p>
            </td>
            <td className="bg-F1F1F2">
              <p className="m-0">{t("Translation.R3.T2")}</p>
            </td>
            <td className="bg-E6E7E8">
              <p className="m-0">{t("Translation.R3.T3")}</p>
            </td>
            <td className="bg-DCDDDE">
              <p className="m-0">{t("Translation.R3.T4")}</p>
            </td>
            <td className="bg-D1D3D4">
              <p className="m-0">{t("Translation.R3.T5")}</p>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="bg-CBBDBF">
              <p className="fw-700 m-0 ">{t("Translation.R4.T1")}</p>
            </td>
            <td
              className="bg-F1F1F2 border-top-white"
              dangerouslySetInnerHTML={{ __html: t("Translation.R4.T2") }}
            ></td>
            <td className="bg-E6E7E8 border-top-white">
              <p className="m-0">{t("Translation.R4.T3")}</p>
            </td>
            <td className="bg-DCDDDE border-top-white">
              <p className="m-0">{t("Translation.R4.T4")}</p>
            </td>
            <td className="bg-D1D3D4 border-top-white">
              <p className="m-0">{t("Translation.R4.T5")}</p>
            </td>
          </tr>

          <tr>
            <td
              rowSpan={2}
              className="text-start bg-E0D8DA border-top-white border-right-white custom-td-w-13-percent"
            >
              <p
                className="fw-700 mb-1 ms-3 me-2"
                dangerouslySetInnerHTML={{ __html: t("Translation.R5.T1") }}
              ></p>
              <p
                className="text-7C7B7B fs-12 ms-3 me-2"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R5.T1.sp"),
                }}
              ></p>
            </td>
            <td className="bg-D3CCCE border-top-white">
              <p className="fw-700 mb-1">{t("Translation.R5.T2")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R5.T2.sp"),
                }}
              ></p>
            </td>
            <td colSpan={4} className="bg-white border-top-white">
              <p className="m-0">{t("Translation.R5.T3")}</p>
            </td>
          </tr>

          <tr>
            <td className="bg-CAC3C6 border-top-white">
              <p className="fw-700 mb-1">{t("Translation.R6.T1")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R6.T1.sp"),
                }}
              ></p>
            </td>
            <td
              className="bg-F1F1F2 border-top-white"
              dangerouslySetInnerHTML={{ __html: t("Translation.R6.T2") }}
            ></td>
            <td
              className="bg-E6E7E8 border-top-white"
              dangerouslySetInnerHTML={{ __html: t("Translation.R6.T3") }}
            ></td>
            <td
              className="bg-DCDDDE border-top-white"
              dangerouslySetInnerHTML={{ __html: t("Translation.R6.T4") }}
            ></td>

            <td className="bg-D1D3D4 border-top-white">
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R6.T5") }}
              ></p>
            </td>
          </tr>
        </table>
        <table className="w-100" id="cancellationTable">
          <tr>
            <td colSpan={6} className="bg-gold-light2">
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R7.T1") }}
              ></p>
            </td>
          </tr>
          <tr style={{ borderBottom: "4px solid #56273A" }}>
            <td colSpan={2} className="bg-56273A">
              <p className="fw-700 m-0 text-gold">{t("Translation.R1.T1")}</p>
            </td>
            <td className="bg-F1F1F2">
              <p className="fw-700 m-0">{t("Translation.R1.T2")}</p>
            </td>
            <td className="bg-E6E7E8">
              <p className="fw-700 m-0">{t("Translation.R1.T3")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R1.T3.sp"),
                }}
              ></p>
            </td>
            <td className="bg-DCDDDE">
              <p className="fw-700 m-0 ">{t("Translation.R1.T4")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R1.T4.sp"),
                }}
              ></p>
            </td>
            <td className="bg-D1D3D4">
              <p
                className="fw-700 m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R1.T5") }}
              ></p>
            </td>
          </tr>

          <tr>
            <td colSpan={2} className="bg-E0D8DA">
              <p className="fw-700 m-0 ">{t("Translation.R8.T1")}</p>
            </td>
            <td className="bg-F1F1F2">
              <p className="m-0">{t("Translation.R8.T2")}</p>
            </td>
            <td className="bg-E6E7E8">
              <p className="m-0">{t("Translation.R8.T3")}</p>
            </td>
            <td className="bg-DCDDDE">
              <p className="m-0">{t("Translation.R8.T4")}</p>
            </td>
            <td className="bg-D1D3D4">
              <p className="m-0">{t("Translation.R8.T5")}</p>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="bg-CBBDBF">
              <p className="fw-700 m-0 ">{t("Translation.R9.T1")}</p>
            </td>
            <td className="bg-F1F1F2 border-top-white">
              <p className="m-0">{t("Translation.R9.T2")}</p>
            </td>
            <td className="bg-E6E7E8 border-top-white">
              <p className="m-0">{t("Translation.R9.T3")}</p>
            </td>
            <td className="bg-DCDDDE border-top-white">
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R9.T4") }}
              ></p>
            </td>
            <td className="bg-D1D3D4 border-top-white">
              <p className="m-0">{t("Translation.R9.T5")}</p>
            </td>
          </tr>
        </table>
      </div>
      <div className="d-block d-md-none">
        <table className="w-100 mb-4" id="cancellationTable">
          <tr>
            <td colSpan={5} className="bg-gold-light2 px-2">
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R2.T1") }}
              ></p>
            </td>
          </tr>

          <tr>
            <td className="bg-56273A">
              <p className="fw-700 m-0 text-gold">{t("Translation.R1.T1")}</p>
            </td>
            <td className="bg-F1F1F2">
              <p className="fw-700 m-0">{t("Translation.R1.T2")}</p>
            </td>
            <td className="bg-E6E7E8">
              <p className="fw-700 m-0">{t("Translation.R1.T3")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R1.T3.sp"),
                }}
              ></p>
            </td>
            <td className="bg-DCDDDE">
              <p className="fw-700 m-0 ">{t("Translation.R1.T4")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R1.T4.sp"),
                }}
              ></p>
            </td>
            <td className="bg-D1D3D4">
              <p
                className="fw-700 m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R1.T5") }}
              ></p>
            </td>
          </tr>
          <tr>
            <td className="bg-E0D8DA">
              <p className="fw-700 m-0">{t("Translation.R3.T1")}</p>
            </td>
            <td className="bg-F1F1F2">
              <p className="m-0">{t("Translation.R3.T2")}</p>
            </td>
            <td className="bg-E6E7E8">
              <p className="m-0">{t("Translation.R3.T3")}</p>
            </td>
            <td className="bg-DCDDDE">
              <p className="m-0">{t("Translation.R3.T4")}</p>
            </td>
            <td className="bg-D1D3D4">
              <p className="m-0">{t("Translation.R3.T5")}</p>
            </td>
          </tr>

          <tr>
            <td className="bg-CBBDBF">
              <p className="fw-700 m-0 ">{t("Translation.R4.T1")}</p>
            </td>
            <td
              className="bg-F1F1F2 border-top-white"
              dangerouslySetInnerHTML={{ __html: t("Translation.R4.T2") }}
            ></td>
            <td className="bg-E6E7E8 border-top-white">
              <p className="m-0">{t("Translation.R4.T3")}</p>
            </td>
            <td className="bg-DCDDDE border-top-white">
              <p className="m-0">{t("Translation.R4.T4")}</p>
            </td>
            <td className="bg-D1D3D4 border-top-white">
              <p className="m-0">{t("Translation.R4.T5")}</p>
            </td>
          </tr>
          <tr>
            <td
              colSpan={5}
              className=" bg-E0D8DA border-top-white border-right-white"
            >
              <p
                className="fw-700 mb-1 "
                dangerouslySetInnerHTML={{ __html: t("Translation.R5.T1") }}
              ></p>
              <p
                className="text-7C7B7B fs-12  mb-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R5.T1.sp"),
                }}
              ></p>
            </td>
          </tr>
          <tr>
            <td className="bg-D3CCCE border-top-white">
              <p className="fw-700 mb-1">{t("Translation.R5.T2")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R5.T2.sp"),
                }}
              ></p>
            </td>
            <td colSpan={4} className="bg-white border-top-white">
              <p className="m-0">{t("Translation.R5.T3")}</p>
            </td>
          </tr>

          <tr>
            <td className="bg-CAC3C6 border-top-white">
              <p className="fw-700 mb-1">{t("Translation.R6.T1")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R6.T1.sp"),
                }}
              ></p>
            </td>
            <td
              className="bg-F1F1F2 border-top-white"
              dangerouslySetInnerHTML={{ __html: t("Translation.R6.T2") }}
            ></td>
            <td
              className="bg-E6E7E8 border-top-white"
              dangerouslySetInnerHTML={{ __html: t("Translation.R6.T3") }}
            ></td>
            <td
              className="bg-DCDDDE border-top-white"
              dangerouslySetInnerHTML={{ __html: t("Translation.R6.T4") }}
            ></td>

            <td className="bg-D1D3D4 border-top-white">
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R6.T5") }}
              ></p>
            </td>
          </tr>
        </table>
        <table className="w-100" id="cancellationTable">
          <tr>
            <td colSpan={5} className="bg-gold-light2">
              <p
                className="m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R7.T1") }}
              ></p>
            </td>
          </tr>
          <tr>
            <td className="bg-56273A">
              <p className="fw-700 m-0 text-gold">{t("Translation.R1.T1")}</p>
            </td>
            <td className="bg-F1F1F2">
              <p className="fw-700 m-0">{t("Translation.R1.T2")}</p>
            </td>
            <td className="bg-E6E7E8">
              <p className="fw-700 m-0">{t("Translation.R1.T3")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R1.T3.sp"),
                }}
              ></p>
            </td>
            <td className="bg-DCDDDE">
              <p className="fw-700 m-0 ">{t("Translation.R1.T4")}</p>
              <p
                className="text-7C7B7B fs-12 m-0"
                dangerouslySetInnerHTML={{
                  __html: t("Translation.R1.T4.sp"),
                }}
              ></p>
            </td>
            <td className="bg-D1D3D4">
              <p
                className="fw-700 m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R1.T5") }}
              ></p>
            </td>
          </tr>

          <tr>
            <td className="bg-CBBDBF">
              <p className="fw-700 m-0 ">{t("Translation.R8.T1")}</p>
            </td>
            <td className="bg-F1F1F2">
              <p className="m-0">{t("Translation.R8.T2")}</p>
            </td>
            <td className="bg-E6E7E8">
              <p className="m-0">{t("Translation.R8.T3")}</p>
            </td>
            <td className="bg-DCDDDE">
              <p className="m-0">{t("Translation.R8.T4")}</p>
            </td>
            <td className="bg-D1D3D4">
              <p className="m-0">{t("Translation.R8.T5")}</p>
            </td>
          </tr>

          <tr>
            <td className="bg-CBBDBF">
              <p className="fw-700 m-0 ">{t("Translation.R9.T1")}</p>
            </td>
            <td className="bg-F1F1F2">
              <p className="m-0">{t("Translation.R9.T2")}</p>
            </td>
            <td className="bg-E6E7E8">
              <p className="m-0">{t("Translation.R9.T3")}</p>
            </td>
            <td className="bg-DCDDDE">
              <p
                className="fw-700 m-0"
                dangerouslySetInnerHTML={{ __html: t("Translation.R9.T4") }}
              ></p>
            </td>
            <td className="bg-D1D3D4">
              <p className="m-0">{t("Translation.R9.T5")}</p>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default TNCTables;
