import { useEffect, useState } from "react";

import {
  getAutocompleteAddresses,
  getLocationByPlaceid,
} from "../../../actions/googleAPI";
import AirportLogoMaps from "../../../images/airport-logo-maps.svg";
import PointLogoMaps from "../../../images/point-logo-maps.svg";
import StationLogoMaps from "../../../images/station-logo-maps.svg";
import HotelLogoMaps from "../../../images/hotel-logo-maps.svg";
import RestaurantLogoMaps from "../../../images/restaurant-logo-maps.svg";

const AdminInputLocation = ({
  type = "text",
  prefixIcon = null,
  suffixIcon = null,
  placeholder = "",
  mb = "mb-3",
  onSelectLocation,
  name = "",
  label = null,
  hasIndex = false,
  index = null,
  value = "",

}) => {

  const [locationText, setLocationText] = useState("");
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    value !== "" && setLocationText(value);
  
  }, [value])
  

  const handleOnChange = async (value) => {
    setLocationText(value)
    if (value.trim().length === 0) {
      setLocations([]);
    } else {
      var getLocationsResp = await getAutocompleteAddresses(value);
      setLocations(getLocationsResp);
    }
  };

  const handleSelectLocation = async (location) => {
    let detailLocation = await getLocationByPlaceid(location.place_id)
    detailLocation.name = location?.structured_formatting?.main_text;
    detailLocation.description = location.description;
    
    if (hasIndex) {
      onSelectLocation(detailLocation, index)
    } else {
      onSelectLocation(detailLocation)
    }
    setLocationText(location.structured_formatting.main_text)
    setLocations([]);
  }
  return (
    <>
      <div className={`${mb} position-relative`}>
        {label && <label className="mb-1">{label}</label>}
        <div className="w-100 rounded border">
          <div className="d-flex gap-2 input-container">
            {prefixIcon && (
              <img src={prefixIcon} alt="" srcSet="" className="ms-3" />
            )}
            <input
              className={`input-field w-100 rounded border-0 py-2 no-calendar ${prefixIcon != null ? "" : "px-3"
                }`}
              type={type}
              placeholder={placeholder}
              onChange={(e) => handleOnChange(e.target.value)}

              value={locationText}
            />
            {suffixIcon && (
              <img src={suffixIcon} alt="" srcSet="" className="me-3" />
            )}
          </div>
        </div>
      </div>
      {locations.length > 0 && (
        <div
        style={{
          position: "absolute",
          backgroundColor: "white",
          zIndex: 1000,
          maxHeight: "205px",
          overflow: "auto",
          border: "1px solid #ddd",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        }}
        >
          {locations.map((item, index) => <div key={index}>
            <div className="d-flex border-bottom hover pointer px-3 gap-3" onClick={() => handleSelectLocation(item)}>
              <img
                src={item.types.includes("airport") ? AirportLogoMaps : item.types.includes("lodging") ? HotelLogoMaps : item.types.includes("train_station") ? StationLogoMaps : item.types.includes("restaurant") ? RestaurantLogoMaps : PointLogoMaps}
                className=" text-center"
                alt="Airport icon"
              />
              <div className=" py-2">
                <p className="m-0 fw-bold">{item.structured_formatting.main_text}</p>
                <small className="fs-13">{item.structured_formatting.secondary_text}</small>
              </div>
            </div>
          </div>)}
        </div>
      )}
    </>
  );
};

export default AdminInputLocation;
