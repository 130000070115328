import moment from "moment";
import DatePicker from "react-datepicker";

const AdminInputDatePicker = ({
  prefixIcon = null,
  suffixIcon = null,
  placeholder = "",
  mb = "mb-3",
  onSelectDate,
  label = null,
  value = null,
  hasIndex = false,
  index = null,
  onPrefixIconClick = null, 
  onSufixIconClick = null, 
  disable = false,
}) => {
  return (
    <>
      <div className={mb}>
        {label && <label className="mb-1">{label}</label>}
        <div className="w-100 rounded border">
          <div className="d-flex gap-2 input-container">
            {prefixIcon && (
              <img
                src={prefixIcon}
                alt=""
                srcSet=""
                className="ms-3"
                onClick={onPrefixIconClick} 
                style={{ cursor: 'pointer' }} 
              />
            )}

            <DatePicker
              dateFormat="yyyy-MM-dd"
              className={`input-field w-100 rounded border-0 py-2 ${
                prefixIcon != null ? "" : "px-3"
              }`}
              placeholderText={placeholder}
              onChange={(date) => {
                if (hasIndex) {
                  onSelectDate(date, index);
                } else {
                  onSelectDate(date);
                }
              }}
              selected={value}
              disabled={disable}
            />
            {suffixIcon && (
              <img src={suffixIcon} alt="" srcSet="" className="me-3"  onClick={onSufixIconClick}/>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminInputDatePicker;
