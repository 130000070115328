import back_icon from "../../../../images/back_icon.svg";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { getCustomerNotification, getCustomerNotificationDetail } from "../../../../actions/notification";
import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import Spinner from "../../../layout/Spinner";
import Moment from "react-moment";
import arrow_right_icon from "../../../../icons/arrow_right.svg";

const NotificationDetail = ({ match }) => {
  const customerNotificationDetail = useSelector((state) => state.notification);
  const { loading, notification } = customerNotificationDetail;
  const settings = useSelector((state) => state.setting);
  const { lang } = settings;
  const dispatch = useDispatch();
  const { notif_id } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCustomerNotificationDetail(notif_id));
  }, [dispatch]);

  console.log(loading ? loading : notification?.titleEN)

  return (
    <>
      {loading ? <Spinner /> : (
        <div className="card mt-3">
          <div className="card-body">
            <div className="p-3">
              <div className="d-flex align-items-center mb-3">
                <Link to="/my-page/notifications" style={{ textDecoration: "none" }}>
                  <p
                    className="fw-100 text-start text-dark noto d-inline back-to-vehicle-selection-button"
                  >
                    <div
                      className="col-auto back-to-vehicle-selection-button"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={back_icon}
                        className=""
                        alt="Clock icon"
                        style={{ marginRight: "5px" }}
                      />
                      &nbsp; Back
                    </div>
                  </p>
                </Link>
              </div>

              {/* START OF HEADER SECTION */}
              <p className="noto fs-16 fw-900">
                {lang === "EN" ? notification?.titleEN : notification?.titleJP}
              </p>
              <hr className="divider-grey" />

              {/* END OF HEADER SECTION */}


              {/* START OF BODY SECTION */}
              <>{/* Boddy message */}
                <p className="my-4">
                  {lang === "EN" ? notification?.descriptionEN : notification?.descriptionJP}
                </p>
              </>

              {/* Booking Info */}
              {notification?.isShowBooking && (
                <>
                  <hr className="divider-dash" />  {/* # This divider only for booking modification request */}
                  <p className="fw-14 text-primary fw-700">Requested Booking Details</p>{/* Subtitle */}
                  <p className="fw-14 fw-700 m-0">Date & Time:</p>
                  <p className="fw-14 m-0">
                    Pick-up: <Moment format="dddd, MMMM Do, YYYY HH:mm">{notification.bookingId.travelDate}</Moment>
                  </p>
                  <p className="fw-14 m-0">
                    Drop-off: <Moment format="dddd, MMMM Do, YYYY HH:mm">{notification.bookingId.bookingInfo[0].cutomerDropOffTimeWithAdditinalTime}</Moment>
                  </p>

                  <p className="fw-14 fw-700 mt-3 mb-0">Pick-up Location:</p>
                  <p className="fw-14 m-0">{notification?.bookingId?.pickupLocation?.label}</p>

                  <p className="fw-14 fw-700 mt-3 mb-0">Drop-off Location:</p>
                  {notification?.bookingId?.destinations.map((item, index) => {
                    return (
                      <div>
                        <p className="fw-14 m-0">{item.label}</p>
                        {index == notification?.bookingId?.destinations?.length - 1 ? "" : <br />}
                      </div>
                    );
                  })}

                  <p className="fw-14 fw-700 mt-3 mb-0">Message・Inquiry:</p>
                  {
                    (notification?.bookingId?.message?.filter(msg => msg.note != "").length == 0) ? (
                      <p className="fw-14 m-0">None</p>
                    ) : (
                      notification?.bookingId?.message?.map((val) => {
                        return (
                          <p className="fw-14 m-0">{val.note}</p>
                        );
                      })
                    )
                  }
                </>
              )}
              {/* END OF BODY SECTION */}


              {/* START OF BUTTON ACTION SECTION */}
              {notification?.isUseButton && (
                <div className="d-grid mt-5">
                  {/* <Link to={notification?.buttonDetail?.link} style={{textDecoration: "none"}}> */}
                  <button
                    type="button"
                    className="btn btn-primary py-2"
                    onClick={() => {
                      window.location.href = notification?.buttonDetail?.link
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-center  d-inline">
                      {lang == "EN" ? notification?.buttonDetail?.textEN : notification?.buttonDetail?.textJP}
                      <i className="ps-2">
                       <img src={arrow_right_icon} alt="" srcSet="" />
                      </i>
                    </div>
                  </button>
                  {/* </Link> */}
                </div>
              )}
              {/* EMD OF BUTTON ACTION SECTION */}


            </div>
          </div>
        </div>
      )
      }
    </>
  );
};

export default NotificationDetail;
