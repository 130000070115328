import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import googleIcon from "../../images/google-icon.svg";
import { login, checkEmailBeforeRegister } from "../../actions/auth";
import { Link } from "react-router-dom/cjs/react-router-dom";
import email_icon from "../../icons/email.svg"
import lock_icon from "../../icons/lock.svg"
import arrow_right_icon from "../../icons/arrow_right.svg"
import i18next from "i18next";

const CustomerRegister = ({
  login,
  isAuthenticated,
  useModal,
  setIsRegister,
  modalId,
  isBooking,
  book,
}) => {
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng.toLowerCase());
  };

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang !== null) changeLanguage(lang);
  }, []);
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    password_confirmation: "",
    sign_up_with_google: false,
    access_token_google: null,
    avatar: null,
  });
  const [isGoogleSignUp, setIsGoogleSignUp] = useState(false);
  const [checkEmailMessage, setCheckEmailMessage] = useState("");
  const [showErrorCheckEmail, setShowErrorCheckEmail] = useState(false);

  useEffect(() => {
    window.gapi.load('client:auth2', () => {
      window.gapi.client.init({
        client_id: '341935228570-l3uovc1akibqn63tjf2lfhiaamvnuk61.apps.googleusercontent.com',
        scope: "email",
        plugin_name: "chat",
        cookie_policy: "single_host_origin"
      });
    });
  }, []);

  const handleSignUpGoogle = async () => {
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();

      // Handle the signed-in user
      const user = await auth2.signIn({ prompt: "consent" });
      const profile = user.getBasicProfile()
      const authResponse = user.getAuthResponse();
      const accessToken = authResponse.access_token;

      const userId = profile.getId();
      const userName = profile.getName();
      const userEmail = profile.getEmail();
      const userImageURL = profile.getImageUrl();

      const checkLoginWithGoogle = await login(userEmail, accessToken, 'google');

      // Log user details
      // console.log("User ID: ", userId);
      // console.log("User Name: ", userName);
      // console.log("User Email: ", userEmail);
      // console.log("User Image URL: ", userImageURL);
      // console.log("Access Token: ", accessToken);

      if (!checkLoginWithGoogle) {
        setFormData({ ...formData, sign_up_with_google: true, email: userEmail, access_token_google: accessToken, avatar: userImageURL });

        const checkEmailStatus = await checkEmailBeforeRegister({ ...formData, sign_up_with_google: true, email: userEmail, access_token_google: accessToken, avatar: userImageURL, role: "individual" })

        if (checkEmailStatus.data.code == 200) {
          setIsGoogleSignUp(true);
        } else {
          setCheckEmailMessage(checkEmailStatus.data.desc);
          setShowErrorCheckEmail(true);
        }
      }

    } catch (error) {
      console.error('Error signing in:', error);
      console.log('Error details:', JSON.stringify(error, null, 2));
    }
  };

  useEffect(() => {
    if (isGoogleSignUp) {
      const button = document.getElementById("closeModal");

      button.click();

      history.push({
        pathname: '/register',
        state: { formData },
      });
    }
  }, [isGoogleSignUp, history, formData])

  const onSubmit = async (e) => {
    const checkEmailStatus = await checkEmailBeforeRegister({ ...formData, role: 'individual' });

    if (checkEmailStatus.data.code == 200) {
      e.preventDefault();
      const button = document.getElementById("closeModal");
      button.click();
      history.push({
        pathname: '/register',
        state: { formData },
      });
    } else {
      setFormData({
        ...formData,
        password: '',
        password_confirmation: ''
      })
      setCheckEmailMessage(checkEmailStatus.data.desc);
      setShowErrorCheckEmail(true);
    }
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  if (isAuthenticated) {
    if (isBooking) {
      book();
    } else {
      history.push("/");
      // window.location.reload();
    }
  }
  return (
    <Fragment>
      {useModal == true || isBooking == true ? (
        <div
          className="modal fade"
          id={modalId}
          tabIndex="1"
          aria-labelledby="loginLabel"
          aria-hidden="true"
        >
          <div className=" modal-dialog modal-lg modal-dialog-centered ">
            <div className="modal-content  border-top-gold">
              <div className="modal-body px-4">
                <div className="row d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-close p-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="closeModal"
                  ></button>
                </div>
                <div className="row d-flex align-items-center mb-3">
                  <div className="col-auto">
                    <h3 className="fw-900 text-secondary">{t("sign_up.sign_up")}</h3>
                  </div>
                  <div className="col">
                    <hr />
                  </div>
                </div>
                {/* START FORM */}
                {showErrorCheckEmail && (
                  <>
                    <div className="alert alert-danger">{t(checkEmailMessage)}</div>
                  </>
                )}
                <div className="row g-3 mb-3">
                  <div className="col form-floating fs-8">
                    <input
                      type="text"
                      className={`form-control contact-form `}
                      id="emailAddress4"
                      placeholder="Email address"
                      name="email"
                      onChange={(e) => onChange(e)}
                      value={formData.email}
                    />
                    <label htmlFor="emailAddress4">
                      <div className="d-flex align-items-center">
                        <img src={email_icon} alt=""  />
                        &nbsp; {t("sign_up.email_address")}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="row g-3 mb-3">
                  <div className="col form-floating fs-8">
                    <input
                      type="password"
                      className={`form-control contact-form `}
                      id="password3"
                      placeholder="Password"
                      name="password"
                      onChange={(e) => onChange(e)}
                      value={formData.password}
                    />
                    <label htmlFor="password3">
                      <div className="d-flex align-items-center">
                        <img src={lock_icon} alt=""  />
                        &nbsp; {t("sign_up.password")}
                      </div>
                    </label>
                  </div>
                </div>

                <div className="row g-3 mb-3">
                  <div className="col form-floating fs-8">
                    <input
                      type="password"
                      className={`form-control contact-form `}
                      id="password_confirmation"
                      placeholder="Password_confirmation"
                      name="password_confirmation"
                      onChange={(e) => onChange(e)}
                      value={formData.password_confirmation}
                    />
                    <label htmlFor="password_confirmation">
                      <div className="d-flex align-items-center">
                        <img src={lock_icon} alt=""  />
                        &nbsp; {t("sign_up.password_confirm")}
                      </div>
                    </label>
                  </div>
                </div>
                <div className="row mt-4 justify-content-center">
                  <div className="col-6 d-grid text-center">
                    <button
                      name=""
                      id=""
                      className="btn btn-primary rounded py-2 px-4"
                      onClick={(e) => onSubmit(e)}
                    >
                      {t("sign_up.proceed")}
                      <i className="ps-2">
                        <img src={arrow_right_icon} alt=""  />
                      </i>
                    </button>
                    <a href="#" className="text-muted mt-3" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#login">
                      <span dangerouslySetInnerHTML={{ __html: t("sign_up.already_have_account") }}></span>
                    </a>

                  </div>
                </div>

                <div className="row align-items-center mt-4">
                  <div className="col">
                    <hr />
                  </div>
                  <div className="col-auto">
                    <p className="p-0 m-0">{t("sign_up.or")}</p>
                  </div>
                  <div className="col">
                    <hr />
                  </div>
                </div>
                <div className="row my-4 justify-content-center ">
                  <div className="col-7 d-grid text-center">
                    <a
                      name=""
                      id=""
                      className="btn btn-secondary rounded py-2 px-4 m-0"
                      role="button"
                      onClick={handleSignUpGoogle}
                    >
                      <img
                        src={googleIcon}
                        className="img-fluid"
                        alt="Efficiency"
                      />
                      &nbsp; {t("sign_up.continue_w_google")}
                    </a>
                  </div>
                </div>
                {/* END FORM */}
              </div>
              {
                process.env.REACT_APP_BUSINESS_AUTH == "true" ? (
                  <div className="container">
                    <div className="row bg-grey-600 py-3" onClick={() => history.push({ pathname: 'business-register' })}
                      data-bs-dismiss="modal">
                      <p className="text-center m-0 noto text-white">
                        {t("For Business Inquiries and B2B Requests, please register from here")}
                      </p>
                    </div>
                  </div>
                ) : ""
              }
            </div>

          </div>
        </div>
      ) : (
        <>
          {/* START FORM */}
          {showErrorCheckEmail && (
            <>
              <div className="alert alert-danger">{t(checkEmailMessage)}</div>
            </>
          )}
          <div className="row g-3 mb-3">
            <div className="col form-floating fs-8">
              <input
                type="text"
                className={`form-control contact-form `}
                id="emailAddress2"
                placeholder="Email address"
                name="email"
                onChange={(e) => onChange(e)}
                value={formData.email}
              />
              <label htmlFor="emailAddress2">
                <div className="d-flex align-items-center">
                  <img src={email_icon} alt=""  />
                  &nbsp; {t("sign_up.email_address")}
                </div>
              </label>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col form-floating fs-8">
              <input
                type="password"
                className={`form-control contact-form `}
                id="password4"
                placeholder="Password"
                name="password"
                onChange={(e) => onChange(e)}
                value={formData.password}
              />
              <label htmlFor="password4">
                <div className="d-flex align-items-center">
                  <img src={lock_icon} alt=""  />
                  &nbsp; {t("sign_up.password")}
                </div>
              </label>
            </div>
          </div>

          <div className="row g-3 mb-3">
            <div className="col form-floating fs-8">
              <input
                type="password"
                className={`form-control contact-form `}
                id="password_confirmation"
                placeholder="Password_confirmation"
                name="password_confirmation"
                onChange={(e) => onChange(e)}
                value={formData.password_confirmation}
              />
              <label htmlFor="password_confirmation">
                <div className="d-flex align-items-center">
                  
                <img src={lock_icon} alt=""  />
                  &nbsp; {t("sign_up.password_confirm")}
                </div>
              </label>
            </div>
          </div>
          <div className="row mt-4 justify-content-center">
            <div className="col-md-6 d-grid text-center">
              <button
                name=""
                id=""
                className="btn btn-primary rounded py-2 px-4"
                href="#"
                onClick={(e) => onSubmit(e)}
              >
                {t("sign_up.proceed")}
                <i className="ps-2">
                  <img src={arrow_right_icon} alt=""  />
                </i>
              </button>
              <Link className="text-muted mt-3 noto-sans" to="/login"> <span
                    dangerouslySetInnerHTML={{ __html: t("sign_up.already_have_account") }}
                  ></span>
                </Link>
              {/* <a href="#" onClick={() => setIsRegister(false)} className="text-muted mt-3">
                <span dangerouslySetInnerHTML={{ __html: t("sign_up.already_have_account") }}></span>
              </a> */}
            </div>
          </div>
          <div className="row align-items-center mt-4">
            <div className="col">
              <hr />
            </div>
            <div className="col-auto">
              <p className="p-0 m-0">{t("sign_up.or")}</p>
            </div>
            <div className="col">
              <hr />
            </div>
          </div>
          <div className="row my-4 justify-content-center ">
            <div className="col-md-7 d-grid text-center">
              <a
                name=""
                id=""
                className="btn btn-secondary rounded py-2 px-4 m-0"
                role="button"
                onClick={handleSignUpGoogle}
              >
                <img src={googleIcon} className="img-fluid" alt="Efficiency" />
                &nbsp; {t("sign_up.continue_w_google")}
              </a>
            </div>
          </div>
          {process.env.REACT_APP_BUSINESS_AUTH == "true" ? (
            <div onClick={() => history.push({ pathname: 'business-register' })} className="d-block d-lg-none mt-5 border-gold bg-gold-light-50 p-2 rounded">
              <p className="text-center m-0 px-4 noto">For Business Inquiries and B2B Requests, please register from here</p>
            </div>
          ) : ""}

        </>

      )}
    </Fragment>
  );
};

CustomerRegister.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(CustomerRegister);
