import {
  ADD_QUOTATION,
  GET_QUOTATIONS,
  GET_QUOTATION,
  DELETE_QUOTATION,
  QUOTATION_ERROR,
} from "../types";
import customAxios from "../../utils/customAxios";
import moment from "moment";

export const getQuotations = (params) => async (dispatch) => {
  try {
    const response = await customAxios.get("/api/admin/quotations", {
      params: {
        search: params.search,
        page: params.page,
        limit: params.limit,
        orderBy: params.orderBy,
        sortBy: params.sortBy,
        disableCalculateTotal: false,
        draw: 1,
        status: "ACTIVE,EXPIRED"
      },
    });
    console.log(response.data.data.data[0]);
    dispatch({
      type: GET_QUOTATIONS,
      payload: response.data.data,
    });
    // return response.data.data.data[0];
  } catch (error) {
    // dispatch({
    //   type: QUOTATION_ERROR,
    //   payload: { msg: error.response.statusText, status: error.response.status },
    // });
    console.error("Error fetching quotation:", error);
  }
}

export const fetchClients = async (query) => {
  try {
    const response = await customAxios.get("/api/users", {
      params: {
        limit: 10,
        search: query,
        page: 1,
        orderBy: "_id",
        sortBy: "asc",
        disableCalculateTotal: true,
        draw: 1,
        role: "individual,business",
      },
    });
    return response
  } catch (error) {
    console.error("Error fetching clients:", error);
  }
};

export const postQuotation = async (data) => {
  try {
    const response = await customAxios.post("/api/admin/quotations", data);
    return response
  } catch (error) {
    console.log(error)
    if (error.response && error.response.data && error.response.data.errors) {
      const errorMessages = error.response.data.errors;
      throw new Error(errorMessages.join("\n")); // Throw a combined error message
    } else {
      throw new Error("An unexpected error occurred while add transfer.");
    }
  }
}

export const postEditQuotation = async (data,quotationId,bookingId) => {
  try {
    const response = await customAxios.post(`/api/admin/quotations/${quotationId}/booking/${bookingId}/edit`, data);
    return response
  } catch (error) {

    if (error.response && error.response.data && error.response.data.errors) {
      const errorMessages = error.response.data.errors;
      throw new Error(errorMessages.join("\n")); // Throw a combined error message
    } else {
      throw new Error("An unexpected error occurred while edit transfer.");
    }
  }
}


export const getDetails = async (id) => {
  try {
    const response = await customAxios.get("/api/admin/quotations", {
      params: {
        search: "",
        page: 1,
        limit: 20,
        orderBy: "_id",
        sortBy: "asc",
        disableCalculateTotal: false,
        draw: "",
        id: id,
      },
    });
    return response.data.data.data[0];
  } catch (error) {
    console.error("Error fetching quotation:", error);
  }
}

export const getBookingType = (bookingType, isAirportTransfer) => {
  switch (bookingType) {
    case "byTheHour":
      return "By The Hour"
    case "specifiedDestination":
      if (isAirportTransfer) {
        return "Airport Transfer"
      } else {
        return "Specified Destination"
      }
    default:
      return "";
  }
}

export const formatTravelDate = (dateString) => {
  if(!dateString){
    return "";
  }
  const date = new Date(dateString);

  // Format bagian utama (bulan, hari, tahun)
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  const mainPart = new Intl.DateTimeFormat('en-US', options)
    .format(date)
    .replace(',', '.'); // Ganti koma dengan titik

  // Format hari dalam minggu (uppercase)
  const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'short' })
    .format(date)
    .toUpperCase();

  return `${mainPart} (${weekday})`;
};

export const formatTime = (dateString) => {

  if(!dateString){
    return "";
  }
  const date = new Date(dateString);

  // Ambil jam dan menit dalam UTC
  const hours = date.getUTCHours().toString().padStart(2, '0'); // UTC hours
  const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // UTC minutes

  return `${hours}:${minutes}`;
};


export const deleteTransfer = async (quotationId, bookingId) => {
  try {
    const response = await customAxios.post("/api/admin/quotations/delete-booking", {
      quotationId, bookingId
    });
    return response;
  } catch (error) {
    console.error("Error deleting booking:", error);
  }
}



export const activeQuotation = async (quotationId, quotationInformation, selectedUser, notes) => {
  try {
    console.log(quotationInformation.issueDate);
    console.log(selectedUser);

    var issueDate = moment
      .utc(quotationInformation.issueDate, "YYYY-MM-DD")
      .local()
      .format("YYYY-MM-DD")

    var validUntil = moment
      .utc(quotationInformation.validUntil, "YYYY-MM-DD")
      .local()
      .format("YYYY-MM-DD")


    const response = await customAxios.post("/api/admin/quotations/activate", {
      "id": quotationId,
      "quotationID": quotationInformation.quotationId,
      "quotationNumber": quotationInformation.bookingReference,
      "issueDate": new Date(`${issueDate}T00:00:00Z`),
      "expiredDate": new Date(`${validUntil}T00:00:00Z`),
      "user": selectedUser._id,
      "notes" : notes ?? "",
    });
    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errors) {
      const errorMessages = error.response.data.errors;
      throw new Error(errorMessages); // Throw a combined error message
    } else {
      throw new Error("An unexpected error occurred while activating the quotation.");
    }
  }
}

export const addUser = async (formData) => {
  try {
    const response = await customAxios.post("/api/users/register-guest-quotation", {
      name: formData.name,
      email: formData.email,
      phone:  formData.phoneNumber,
      country: formData.country ,
      pronounced: formData.honorific,
      prefixNumber: formData.prefixNumber,
      countryCode: formData.countryCode,
      postCode: formData.postalCode,
      address: formData.billingAddress
    });
    return response;
  } catch (error) {
    console.error("Error add user:", error);
    throw error; 
  }
}

export const formatPaxData = (data) => {
  if(!data) {
    return "";
  }
  const filteredData = data.filter(item => item.paxcount > 0);

  return filteredData.map(item => `${item.paxtype}: ${item.paxcount}`).join(', ');
};

export const formatLuggageData = (data) => {

  if(!data) {
    return "";
  }
  const filteredData = data.filter(item => item.luggagecount > 0);

  if (filteredData.length === 0) {
    return "None";
  }

  const typeMapping = {
    "CHECK-IN SIZE": "Check-in",
    "CABIN SIZE": "Cabin"
  };

  // Format menjadi string
  return filteredData
    .map(item => `${typeMapping[item.luggagetype] || item.luggagetype}: ${item.luggagecount}`)
    .join(', ');
};

export const formatChildSeatData = (data) => {

  if(!data) {
    return "";
  }
  const filteredData = data.filter(item => item.childSeatCount > 0);

  if (filteredData.length === 0) {
    return "None";
  }

  return filteredData
    .map(item => `${item.childSeatType} Seat: ${item.childSeatCount}`)
    .join(', ');
};

export const getMeetAssistNames = (data) => {
  if(!data) {
    return "";
  }
  return data
    .filter(item => item.type === "meetAssistArrival" || item.type === "meetAssistDeparture")
    .map(item => item.name)
    .join(', ');
};


export const paymentEligibility = async (id) => {
  try {
    console.log(id);

    const response = await customAxios.post("/api/admin/quotations/payment-eligibility", {
      "id": id,
    });

    return response.data;
  } catch (error) {
    console.error("Error deleting booking:", error);
  }
}

export const finishPayment = async (reqBody) => {
  try {
    const response = await customAxios.post("/api/admin/quotations/finish-payment",
      reqBody
    );

    return response.data;
  } catch (error) {
    console.error("Error deleting booking:", error);
  }
}


export const duplicateBooking = async (reqBody) => {
  try {
    const response = await customAxios.post("/api/admin/quotations/duplicate-booking",
      reqBody
    );

    return response.data;
  } catch (error) {
    console.error("Error deleting booking:", error);
    throw error; 
  }
}
