import { useEffect, useRef, useState } from "react";

const AdminInputLuggage = ({
  type = "text",
  prefixIcon = null,
  suffixIcon = null,
  placeholder = "",
  mb = "mb-3",
  label = null,
  totalCabin,
  totalCheckin,
  onChange,
  value,
}) => {
  const [openPanel, setOpenPanel] = useState(false);
  const handleInputOnClick = () => {
    setOpenPanel(!openPanel);
  };
  const panelRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setOpenPanel(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={`${mb} position-relative`}>
        {label && <label className="mb-1">{label}</label>}
        <div className="w-100 rounded border">
          <div className="d-flex gap-2 input-container">
            {prefixIcon && (
              <img src={prefixIcon} alt="" srcSet="" className="ms-3" />
            )}
            <input
              className={`input-field w-100 rounded border-0 py-2 no-calendar pointer ${
                prefixIcon != null ? "" : "px-3"
              }`}
              type={type}
              readOnly={true}
              placeholder={placeholder}
              onClick={handleInputOnClick}
              value={value}
            />
            {suffixIcon && (
              <img src={suffixIcon} alt="" srcSet="" className="me-3" />
            )}
          </div>
        </div>
        {openPanel && (
            <div className="border rounded mt-2" ref={panelRef} style={{
              position: "absolute",
              backgroundColor: "white",
              zIndex: 1000,
              overflow: "auto",
              border: "1px solid #ddd",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}>
              <div className="d-flex gap-3 border-bottom p-3 justify-content-between align-items-center">
                <p className="m-0">Cabin Size <br /> (55x100x20cm)</p>
                <div className="d-flex gap-4 align-items-center">
                  <button
                    type="button"
                    className="btn btn-circle btn-outline-dark"
                    onClick={() => onChange("cabin", -1)}
                  >
                    <i className="bi bi-dash"></i>
                  </button>
                  <p className="m-0">{totalCabin}</p>
                  <button
                    type="button"
                    className="btn btn-circle btn-outline-dark"
                    onClick={() => onChange("cabin", 1)}
                  >
                    <i className="bi bi-plus"></i>
                  </button>
                </div>
              </div>
              <div className="d-flex gap-3 border-bottom p-3 justify-content-between align-items-center">
                <p className="m-0">Check-in Size <br /> (100x100x80cm)</p>
                <div className="d-flex gap-4 align-items-center">
                  <button
                    type="button"
                    className="btn btn-circle btn-outline-dark"
                    onClick={() => onChange("checkin", -1)}
                  >
                    <i className="bi bi-dash"></i>
                  </button>
                  <p className="m-0">{totalCheckin}</p>
                  <button
                    type="button"
                    className="btn btn-circle btn-outline-dark"
                    onClick={() => onChange("checkin", 1)}
                  >
                    <i className="bi bi-plus"></i>
                  </button>
                </div>
              </div>
            </div>
        )}
      </div>
    </>
  );
};

export default AdminInputLuggage;
