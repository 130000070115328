import Layout from "../../layout/Layout";
const ComponentPage = () => {
  return (
    <>
      <Layout>
        <main
          className="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-4"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div className="bg-white rounded p-3">
          </div>
        </main>
      </Layout>
    </>
  );
};

export default ComponentPage;
