import React, { useEffect } from 'react';

const QuotationModal = ({
  show,
  title,
  children,
  footerContent,
  size = '',
  centered = false, 
  onClose,
}) => {
  useEffect(() => {
    if (show) {
      document.body.classList.add('modal-open'); // Tambahkan kelas modal-open
    } else {
      document.body.classList.remove('modal-open'); // Hapus kelas modal-open
    }

    // Cleanup saat komponen unmount
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [show]);
  return (
    <>
      <div
        className={`modal fade ${show ? 'show d-block' : ''}`}
        tabIndex="-1"
        style={show ? { backgroundColor: 'rgba(0, 0, 0, 0.5)' } : {}}
        aria-labelledby="sharedModalLabel"
        aria-hidden={!show}
        data-bs-backdrop="static" 
        data-bs-keyboard="false"
      >
        <div
          className={`modal-dialog ${size} ${centered ? 'modal-dialog-centered' : ''}`} // Conditionally add centering class
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">{children}</div>
            {footerContent && <div className="modal-footer">{footerContent}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotationModal;
