/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "../../layout/Layout";
import back_icon from "../../../icons/dashboard/back_icon.svg";
import selected_radio_icon from "../../../images/selected-radio.svg";
import unselected_radio_icon from "../../../images/un-selected-radio.svg";
import plane_gold_icon from "../../../images/plane-gold.svg";
import reference_icon from "../../../images/reference-icon.svg";
import baby_seat from "../../../images/baby-seat.svg";
import infant_seat from "../../../images/infant-seat.svg";
import toddler_seat from "../../../images/toddler-seat.svg";
import child_seat from "../../../images/child-seat.svg";
import { useState } from "react";
import Select from "react-select";
import { useParams, useHistory } from "react-router-dom";
import { ByTheHourQuotation } from "./ByTheHourQuotation";
import { SpecifiedDestinationQuotation } from "./SpecifiedDestinationQuotation";
import { getAvailableVehicle } from "../../../actions/admin/booking";
import { getMeetAssistPrice } from "../../../actions/admin/settings"
import add_icon from "../../../icons/dashboard/add.svg";
import moment from "moment";
import AdminInput from "../components/AdminInput";
import AdminTextArea from "../components/AdminTextArea";
import { postQuotation, duplicateBooking, postEditQuotation } from "../../../actions/admin/quotation";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import AdminInputDatePicker from "../components/AdminInputDatePicker";
import date_icon from "../../../icons/dashboard/date_icon.svg";
import plus_icon from "../../../icons/plus.svg";
import input_delete_x_icon from "../../../icons/input-delete-x-icon.svg";
import { useEffect } from "react";
import AdminModal from "../components/AdminModal";

const Transfer = () => {
  const [errorText, setErrorText] = useState("");
  const { quotationId } = useParams();
  const location = useLocation();
  const { quotationInformation } = location.state || {};


  const [errorModal, setErrorModal] = useState(false);

  const closeErrorModal = () => setErrorModal(false);

  const openErrorModal = () => setErrorModal(true);

  if (quotationId) {
  }

  const history = useHistory();
  const [bookingData, setBookingData] = useState(null);
  const [bookingType, setBookingType] = useState("byTheHour");

  const [editFormAvailableVehicle, setEditFormAvailableVehicle] = useState(null);

  const [loadingAddTransfer, setLoadingAddTransfer] = useState(false);
  const [loadingSearchAvailableVehicle, setLoadingSearchAvailableVehicle] =
    useState(false);
  const [availableVehicleData, setAvailableVehicleData] = useState(null);

  const [vehicles, setVehicles] = useState([]);

  const [meetAssistPrice, setMeetAssistPrice] = useState({
    meetAssistBasePrice: 0,
    vipMeetAssistBasePrice: 0,
    vipMeetAssistAdditionalPrice: 0,
  })

  useEffect(() => {
    if (localStorage.getItem("editTransferData")) {
      handleEditMethod();
    }
  }, []);

  useEffect(() => {
    if (availableVehicleData) {
      fetchMeetAssistPrice();
    }
  }, [availableVehicleData]);

  const [additionalInformation, setAdditionalInformation] = useState({
    pronounce: "Mr. ",
    passengerName: null,
    pickupFlightNumber: null,
    dropoffFlightNumber: null,
    pronounceNameBoard: null,
    nameBoard: null,
    meetAssist: [
      {
        meetAssistType: "arrival",
        meetAssistName: "none",
        meetAssistPrice: 0,
      },
      {
        meetAssistType: "departure",
        meetAssistName: "none",
        meetAssistPrice: 0,
      },
    ],
    childSeat: [
      {
        childSeatType: "Baby",
        childSeatCount: 0,
      },
      {
        childSeatType: "Toddler",
        childSeatCount: 0,
      },
      {
        childSeatType: "Child",
        childSeatCount: 0,
      },
      {
        childSeatType: "Infant",
        childSeatCount: 0,
      },
    ],
    childSeatPrice: 0,
  });

  const checkNightHours = async (pickupTime, dropoffTime) => {
    const pickupDateTime = new Date(pickupTime).getUTCHours();
    const dropoffDateTime = new Date(dropoffTime).getUTCHours();

    return (pickupDateTime >= 22 || pickupDateTime < 6) || (dropoffDateTime >= 22 || dropoffDateTime < 6);
  }

  const fetchMeetAssistPrice = async () => {
    try {
      const { meetAssistBasePrice, vipMeetAssistBasePrice, vipMeetAssistAdditionalPrice } = await getMeetAssistPrice();

      const checkNightHoursRes = await checkNightHours(
        new Date(
          `${availableVehicleData?.travelDate}T${availableVehicleData?.pickupTime}:00Z`
        ).toISOString(),
        availableVehicleData.customerDropoffDateTime
      );

      if (checkNightHoursRes) {
        setMeetAssistPrice({
          meetAssistBasePrice: Math.ceil(meetAssistBasePrice * 1.25),
          vipMeetAssistBasePrice: Math.ceil(vipMeetAssistBasePrice * 1.25),
          vipMeetAssistAdditionalPrice: Math.ceil(vipMeetAssistAdditionalPrice * 1.25)
        });
      } else {
        setMeetAssistPrice({
          meetAssistBasePrice,
          vipMeetAssistBasePrice,
          vipMeetAssistAdditionalPrice
        });
      }
    } catch (error) {
      console.error("Error fetching meet assist price:", error);
    }
  };
  function convertToTime(number) {
    // Pisahkan angka menjadi jam dan menit
    const hours = Math.floor(number); // Bagian jam
    const minutes = Math.round((number - hours) * 60); // Bagian menit

    // Formatkan menjadi dua digit
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Gabungkan menjadi format HH:MM
    return `${formattedHours}:${formattedMinutes}`;
}

  const handleEditMethod = () => {
    const editData = JSON.parse(localStorage.getItem("editTransferData"));

    console.log(editData)
    // Handle Booking Type 
    handleBookingType(editData?.bookingType);
    setEditFormAvailableVehicle({
      pickupLocation: editData?.pickupLocation,
      dropoffLocations: editData?.destinations,
      usageDuration: convertToTime(editData?.usageDuration) ,
      travelDate: editData?.travelDate,
      pickupTimeRaw: editData?.pickupTime,
      estpPickupTime: null,
      travelArea: editData?.bookingInfo[0].travelArea,
      luggage: {
        cabinSize: editData?.luggage[1].luggagecount,
        checkinSize: editData?.luggage[0].luggagecount,
      },
      pax: {
        adult: editData?.pax[0].paxcount,
        child: editData?.pax[1].paxcount,
        toddler: editData?.pax[2].paxcount,
        baby: editData?.pax[3].paxcount,
      },
      byTheHourBook: editData?.bookingType === "byTheHour" ? true : false,
      bookingType: editData?.bookingType === "byTheHour" ? "byTheHour" : "specifiedDestination",
      // TODO : Make this 2 key value valid (false, false) is not 
      isFromAirport: false,
      isToAirport: false,
      isFromStation: false,
      isToStation: false,
    });

    const result = (([first, ...rest]) => [first, rest.join(" ")])(editData?.pickupSign.split(" "));
    console.log(editData?.meetAssist);
    setAdditionalInformation({
      pronounce: editData?.passengerPronounced,
      passengerName: editData?.passengerName,
      pickupFlightNumber: editData?.pickupFlightNo,
      dropoffFlightNumber: editData?.dropoffFlightNo,
      pronounceNameBoard: result[0],
      nameBoard: result[1],
      meetAssist: [
        {
          meetAssistType: editData?.meetAssist[0]?.meetAssistType,
          meetAssistName: editData?.meetAssist[0]?.meetAssistName,
          meetAssistPrice: editData?.meetAssist[0]?.meetAssistPrice,
        },
        {
          meetAssistType: editData?.meetAssist[0]?.meetAssistType,
          meetAssistName: editData?.meetAssist[0]?.meetAssistName,
          meetAssistPrice: editData?.meetAssist[1]?.meetAssistPrice,
        },
      ],
      childSeat: [
        {
          childSeatType: "Baby",
          childSeatCount: editData?.childSeat[0]?.childSeatCount,
        },
        {
          childSeatType: "Toddler",
          childSeatCount: editData?.childSeat[1]?.childSeatCount,
        },
        {
          childSeatType: "Child",
          childSeatCount: editData?.childSeat[2]?.childSeatCount,
        },
        {
          childSeatType: "Infant",
          childSeatCount: editData?.childSeat[3]?.childSeatCount,
        },
      ],
      childSeatPrice: editData?.bookingInfo[0]?.childSeatPrice,
    });

    setNote(editData.message ? editData.message[0]?.note || "" : ""); // TODO: TBD (key missing )

  }




  const handleBookingType = (value) => {
    setBookingData(null);
    setEditFormAvailableVehicle(null);
    setAvailableVehicleData(null);
    setVehicles([]);
    setAdditionalInformation({
      pronounce: "Mr. ",
      passengerName: null,
      pickupFlightNumber: null,
      dropoffFlightNumber: null,
      pronounceNameBoard: null,
      nameBoard: null,
      meetAssist: [
        {
          meetAssistType: "arrival",
          meetAssistName: "none",
          meetAssistPrice: 0,
        },
        {
          meetAssistType: "departure",
          meetAssistName: "none",
          meetAssistPrice: 0,
        },
      ],
      childSeat: [
        {
          childSeatType: "Baby",
          childSeatCount: 0,
        },
        {
          childSeatType: "Toddler",
          childSeatCount: 0,
        },
        {
          childSeatType: "Child",
          childSeatCount: 0,
        },
        {
          childSeatType: "Infant",
          childSeatCount: 0,
        },
      ],
      childSeatPrice: 0,
    });

    setBookingType(value);
  };

  const PRONOUNCE = [
    {
      label: "Mr.",
      value: "Mr.",
    }, 
    {
      label: "Ms.",
      value: "Ms.",
    },
    {
      label: "Ms.",
      value: "Ms.",
    },
    {
      label: "Mrs.",
      value: "Mrs.",
    },
    {
      label: "Mr./Ms.",
      value: "Mr./Ms.",
    },
  ];
  const PRONOUNCE_BOARD = [
    {
      label: "Mr.",
      value: "Mr.",
    },
    {
      label: "Ms.",
      value: "Ms.",
    },
    {
      label: "Mrs.",
      value: "Mrs.",
    },
    {
      label: "No Honorific",
      value: " ",
    },
  ];

  const [addTransferType, setAddTransferType] = useState("one_time"); // one_time && recuring
  const handleChangeAddTransferType = (type) => setAddTransferType(type);

  const [recurringTravelDates, setRecurringTravelDates] = useState([""]);
  const handleSelectTravelDate = (date, index) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    setRecurringTravelDates((prevDates) => {
      if (prevDates[index] !== undefined) {
        const updatedDates = [...prevDates];
        updatedDates[index] = formattedDate;
        return updatedDates;
      }
      return prevDates;
    });
  };
  const addReccuringTravelDate = () => {
    setRecurringTravelDates([...recurringTravelDates, ""]);
  }

  const removeReccuringTravelDate = (index) => {
    if (recurringTravelDates.length > 1) {
      setRecurringTravelDates((recurringTravelDates) => [
        ...recurringTravelDates.slice(0, index),
        ...recurringTravelDates.slice(index + 1),
      ]);
    }
  }
  // submit
  const handleSearchAvailableVehicles = async (data) => {
    handleLoadingSearchAvailableVehicle(true);
    setVehicles([]);
    if (data) {
      data.pickupTime = moment
        .utc(data.pickupTimeRaw, "HH:mm")
        .local()
        .format("HH:mm");
      data.travelDate = moment
        .utc(data.travelDate, "YYYY-MM-DD")
        .local()
        .format("YYYY-MM-DD");
      var res = await getAvailableVehicle(data);
      setBookingData(data);
      setAvailableVehicleData(res.data);
      convertVehicleOptions(res.data);
      handleLoadingSearchAvailableVehicle(false);

      if (localStorage.getItem("editTransferData")) { // is edit
        handleSetSelectedVehicleEditData(res.data);
      }
    }
  };

  const handleSetSelectedVehicleEditData = (data) => {
    const dataEdit = JSON.parse(localStorage.getItem("editTransferData"));

    var v = []
    for (let index = 0; index < dataEdit?.bookingInfo.length; index++) {
      const vehcileID = dataEdit.bookingInfo[index].vehicleTypeID;
      const vo = data.availableVehicleTypes.map((vehicle) => ({
        label: vehicle.typename,
        value: vehicle._id,
        info: vehicle,
      }));
      const vehicleInfo = vo.find((vo) => vo.value === vehcileID)?.info;
      v.push(vehicleInfo);
    }
    setVehicles(v);
  }



  const [vehicleOptions, setVehicleOptions] = useState([]);

  const convertVehicleOptions =  (data) => {
    const selectOptions = data.availableVehicleTypes.map((vehicle) => ({
      label: vehicle.typename,
      value: vehicle._id,
      info: vehicle,
    }));
    setVehicles([null]);
    setVehicleOptions(selectOptions);
  };

  const handleOnSelectVehicle = (selectOption, index) => {
    setVehicles((vehicles) => [
      ...vehicles.slice(0, index),
      selectOption.info,
      ...vehicles.slice(index + 1),
    ]);
  };

  const handleAddVehicle = () => {
    setVehicles((vehicles) => [...vehicles, null]);
  };

  const handleLoadingSearchAvailableVehicle = (value) => {
    setLoadingSearchAvailableVehicle(value);
  };

  const handleRemoveVehicle = (index) => {
    if (vehicles.length > 1) {
      setVehicles((vehicles) => [
        ...vehicles.slice(0, index),
        ...vehicles.slice(index + 1),
      ]);
    }
  };

  // Additional Information Handler
  const handlePronouncePassangerName = (selectedOption) => {
    setAdditionalInformation((additionalInformation) => ({
      ...additionalInformation,
      pronounce: selectedOption.value,
    }));
  };

  const handlePassangerName = (e) => {
    setAdditionalInformation((additionalInformation) => ({
      ...additionalInformation,
      passengerName: e.target.value,
    }));
  };

  const handlePickupFlightNumber = (e) => {
    setAdditionalInformation((additionalInformation) => ({
      ...additionalInformation,
      pickupFlightNumber: e.target.value,
    }));
  };
  const handleDropoffFlightNumber = (e) => {
    setAdditionalInformation((additionalInformation) => ({
      ...additionalInformation,
      dropoffFlightNumber: e.target.value,
    }));
  };

  const handlePronounceNameBoard = (selectedOption) => {
    setAdditionalInformation((additionalInformation) => ({
      ...additionalInformation,
      pronounceNameBoard: selectedOption.value,
    }));
  };

  const handleNameBoard = (e) => {
    setAdditionalInformation((additionalInformation) => ({
      ...additionalInformation,
      nameBoard: e.target.value,
    }));
  };

  const handleMeetAndAssist = (type, data) => {
    setAdditionalInformation((prevState) => {
      const updatedMeetAssist = [...prevState.meetAssist];

      switch (type) {
        case "arrival":
          updatedMeetAssist[0] = { ...updatedMeetAssist[0], ...data }; // Merge data for "arrival"
          break;

        case "departure": // Corrected typo
          updatedMeetAssist[1] = { ...updatedMeetAssist[1], ...data }; // Merge data for "departure"
          break;

        default:
          console.warn(`Unhandled type: ${type}`);
          return prevState; // Return unchanged state for invalid type
      }

      return {
        ...prevState,
        meetAssist: updatedMeetAssist,
      };
    });
  };

  const handleChildSeat = (type, total) => {
    setAdditionalInformation((prevState) => {
      const updatedChildSeat = [...prevState.childSeat];
      switch (type) {
        case "Infant":
          updatedChildSeat[0] = {
            ...updatedChildSeat[0],
            childSeatCount: total,
          };
          break;

        case "Toddler":
          updatedChildSeat[1] = {
            ...updatedChildSeat[1],
            childSeatCount: total,
          };
          break;

        case "Child":
          updatedChildSeat[2] = {
            ...updatedChildSeat[2],
            childSeatCount: total,
          };
          break;

        case "Baby":
          updatedChildSeat[3] = {
            ...updatedChildSeat[3],
            childSeatCount: total,
          };
          break;

        default:
          console.warn(`Unhandled type: ${type}`);
          return prevState;
      }

      const totalChildSeatCount = updatedChildSeat.reduce(
        (sum, seat) => sum + seat.childSeatCount,
        0
      );

      return {
        ...prevState,
        childSeat: updatedChildSeat,
        childSeatPrice: totalChildSeatCount * 1100,
      };
    });
  };

  const getPaxList = (pax) => {
    return [
      {
        paxtype: "Adult",
        paxcount: pax.adult,
      },
      {
        paxtype: "Child",
        paxcount: pax.child,
      },
      {
        paxtype: "Toddler",
        paxcount: pax.toddler,
      },
      {
        paxtype: "Baby",
        paxcount: pax.baby,
      },
    ];
  };

  const getLuggageList = (luggage) => {
    return [
      {
        luggagetype: "CHECK-IN SIZE",
        luggagecount: luggage.checkinSize,
      },
      {
        luggagetype: "CABIN SIZE",
        luggagecount: luggage.cabinSize,
      },
    ];
  };

  const [note, setNote] = useState(null);

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  // submit transfer

  const handleSubmitConfirm = async () => {
    if (vehicles.length > 0) {
      handleSubmitTransfer(recurringTravelDates.length > 0 ? true : false);
    }
  };
  const handleSubmitTransfer = async (recurring) => {
    try {

      setLoadingAddTransfer(true);

      var bookings = [];
      var bookingInfo = [];

      const filteredVehicles = vehicles.filter((item) => item !== null);

      for (let i = 0; i < filteredVehicles.length; i++) {
        var data = {
          vehicleTypeName: filteredVehicles[i].typename,
          vehicleTypeID: filteredVehicles[i]._id,
          pax: [
            {
              paxtype: "Adult",
              paxcount: 0,
            },
            {
              paxtype: "Child",
              paxcount: 0,
            },
            {
              paxtype: "Toddler",
              paxcount: 0,
            },
            {
              paxtype: "Baby",
              paxcount: 0,
            },
          ],
          luggage: [
            {
              luggagetype: "CHECK-IN SIZE",
              luggagecount: 0,
            },
            {
              luggagetype: "CABIN SIZE",
              luggagecount: 0,
            },
          ],
          baseprice: filteredVehicles[i].baseprice,
          hourlyprice: filteredVehicles[i].hourlyprice,
          tollFare: filteredVehicles[i].tollFare,
          travelArea: bookingData.travelArea,
          travelAreaPrice: filteredVehicles[i].travelAreaPrice,
          longDistanceSurcharge: filteredVehicles[i].longDistanceSurcharge,
          total: filteredVehicles[i].price,
          childSeat: [
            {
              childSeatType: "Infant",
              childSeatCount: 0,
            },
            {
              childSeatType: "Toddler",
              childSeatCount: 0,
            },
            {
              childSeatType: "Child",
              childSeatCount: 0,
            },
          ],
          childSeatPrice: 0,
          vehicle: null,
        };
        bookingInfo.push(data);
      }
      

      bookings.push({
        travelDate: availableVehicleData.travelDate,
        pickupTime: availableVehicleData.pickupTime,
        pickupDateTime: new Date(
          `${availableVehicleData.travelDate}T${availableVehicleData.pickupTime}:00Z`
        ).toISOString(),
        customerDropoffDateTime: availableVehicleData.customerDropoffDateTime,
        startFromGarage: availableVehicleData.startFromGarage,
        garageArivalTime: availableVehicleData.garageArrivalTime,
        pickupLocation: bookingData.pickupLocation,
        destinations: bookingData.dropoffLocations,
        totalDistance: availableVehicleData.totalDistance,
        garage: availableVehicleData.garage._id,
        pax: getPaxList(bookingData.pax),
        luggage: getLuggageList(bookingData.luggage),
        bookingType: bookingData.bookingType,
        additionalTime: availableVehicleData.additionalTime,
        breakTime: availableVehicleData.breakTime,
        flightTrackingTime: availableVehicleData.flightTrackingTime,
        usageDuration: availableVehicleData.usageDuration,
        billedDuration: availableVehicleData.billedDuration,
        isAirportTransfer:
          bookingData.isFromAirport || bookingData.isToAirport ? true : false,
        isFromAirport: bookingData.isFromAirport,
        isToAirport: bookingData.isToAirport,
        meetAssist: additionalInformation.meetAssist,
        isManyPeople: false,
        bookingInfo: bookingInfo,
        passengerName: additionalInformation.passengerName,
        passengerPronounced: additionalInformation.pronounce,
        childSeat: additionalInformation.childSeat,
        dropoffFlightNo: additionalInformation.dropoffFlightNumber,
        pickupFlightNo: additionalInformation.pickupFlightNumber,
        pickupSign: additionalInformation.pronounceNameBoard + ' ' + additionalInformation.nameBoard,
        noteList: note
          ? [
            {
              note: note,
            },
          ]
          : null,
      });

      var formData;

      if (localStorage.getItem("editTransferData")) {
        var editData = JSON.parse(localStorage.getItem("editTransferData"));
        formData = {
          booking: bookings[0],
        };
      } else {
        formData = {
          bookings: bookings,
        };
      }



      if (quotationId) {
        formData.id = quotationId;
        formData.quotationID = quotationInformation.quotationID;
        formData.quotationNumber = quotationInformation.bookingReference;
        formData.issueDate = moment
          .utc(quotationInformation.issueDate, "YYYY-MM-DD")
          .local()
          .format("YYYY-MM-DD");
        formData.expiredDate = moment
          .utc(quotationInformation.validUntil, "YYYY-MM-DD")
          .local()
          .format("YYYY-MM-DD");
        formData.notes = "";
      }
      var res;
      if (localStorage.getItem("editTransferData")) {
        var editData = JSON.parse(localStorage.getItem("editTransferData"));
        res = await postEditQuotation(formData, quotationId, editData._id);
      } else {
        res = await postQuotation(formData);
      }

      // hit recurring
      if (recurring) {
        var bookingId = res.data.bookings[0]._id;
        var qId = res.data._id;
        const filteredTravelDates = recurringTravelDates.filter((date) => date.trim() !== "");
        if (filteredTravelDates.length > 0) {

          var resDuplicate = await duplicateBooking({
            quotationId: qId,
            bookingId: bookingId,
            duplicateDate: filteredTravelDates,
          });
        }
      }

      history.push("/admin/quotations/create/" + res.data._id);
    } catch (error) {
      setErrorText(error?.message);

      openErrorModal();
    }
    finally {
      setLoadingAddTransfer(false);
    }
  };

  const totalMaxPessanger = vehicles.reduce(
    (acc, item) => acc + parseInt(item?.maxpassenger ?? 0, 10),
    0
  );

  const totalMaxLuggage = vehicles.reduce(
    (acc, item) => acc + parseInt(item?.maxluggages ?? 0, 10),
    0
  );

  return (
    <>
      <Layout>
        <main
          className="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-4"
          style={{ backgroundColor: "#F6F6F6" }}
        >
          <div id="header" className="mb-3">
            <div className="d-inline-flex align-items-center gap-2 pointer" onClick={() => history.goBack()}>
              <img
                src={back_icon}
                alt=""
                srcSet=""
                width={"10px"}
                height={"10px"}
              />
              <p className="noto m-0">Back</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="mt-2">Transfer</h2>
            </div>
          </div>
          <div id="body">
            <div className="bg-white rounded p-3">
              <h5>1. Basic Information</h5>
              <div className="d-flex gap-3 my-3">
                <div
                  className={`${bookingType === "byTheHour"
                    ? "border border-gold"
                    : "border"
                    } rounded p-2 `}
                >
                  <div className="d-flex gap-3">
                    <img
                      src={
                        bookingType === "byTheHour"
                          ? selected_radio_icon
                          : unselected_radio_icon
                      }
                      alt=""
                      srcSet=""
                    />
                    <p
                      className={`m-0 p-0 pointer ${bookingType === "byTheHour" && "fw-bold"
                        } `}
                      onClick={() => handleBookingType("byTheHour")}
                    >
                      Charter/By The Hour
                    </p>
                  </div>
                </div>
                <div
                  className={`${bookingType === "specifiedDestination"
                    ? "border border-gold"
                    : "border"
                    } rounded p-2  `}
                >
                  <div className="d-flex gap-3">
                    <img
                      src={
                        bookingType === "specifiedDestination"
                          ? selected_radio_icon
                          : unselected_radio_icon
                      }
                      alt=""
                      srcSet=""
                    />
                    <p
                      className={`m-0 p-0 pointer ${bookingType === "specifiedDestination" && "fw-bold"
                        } `}
                      onClick={() => handleBookingType("specifiedDestination")}
                    >
                      Airport Transfer/Specified Destination
                    </p>
                  </div>
                </div>
              </div>

              {bookingType === "byTheHour" && (
                  <ByTheHourQuotation
                    searchAvailableVehicles={handleSearchAvailableVehicles}
                    editFormAV={editFormAvailableVehicle}
                  />
              )}

              {bookingType === "specifiedDestination" && (
                <SpecifiedDestinationQuotation
                  searchAvailableVehicles={handleSearchAvailableVehicles}
                  editFormAvailableVehicle={editFormAvailableVehicle}
                />
              )}

              <h5 className="mt-4">2. Vehicle Selection</h5>

              {
                vehicles.filter(value => value !== null).length > 0 ? parseInt(bookingData?.luggage?.cabinSize + bookingData?.luggage?.checkinSize) > totalMaxLuggage || parseInt(bookingData?.pax?.adult + bookingData?.pax?.child + bookingData?.pax?.toddler + bookingData?.pax?.baby) > totalMaxPessanger ? <p className="text-danger">The number of passengers or the luggage doesn't fit. Please add another vehicle.</p> : <></> : <></>
              }

              <div className="row mt-3">
                {vehicles.map((vehicle, index) => (
                  <div className="col-4  mb-3" key={index}>
                    <p className="noto m-0">Vehicle #{index + 1}</p>
                    <div className="d-flex gap-2 align-items-center">
                      <Select
                        className="w-100"
                        options={vehicleOptions}
                        onChange={(selectedOption) =>
                          handleOnSelectVehicle(selectedOption, index)
                        }
                        value={{
                          label: vehicle?.typename,
                          value: vehicle?._id,
                          info: vehicle,
                        }}
                      ></Select>
                      {index > 0 && (
                        <p
                          className="m-0 pointer"
                          onClick={() => handleRemoveVehicle(index)}
                        >
                          X
                        </p>
                      )}
                    </div>
                    {vehicle != null && (
                      <>
                        <div className="bg-gold-FFF8E8 rounded p-3 mt-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="">
                              <p className="fs-10 fw-700 mb-2">DETAILS</p>
                              <ul className="mb-0">
                                {/* <li>From {JSON.stringify(vehicle)} </li> */}
                                <li>
                                  From{" "}
                                  {vehicle.baseprice.toLocaleString() + " JPY"}{" "}
                                  | min. {vehicle.basepricehours} hours
                                </li>
                                <li>Up to {vehicle.maxpassenger} Pax</li>
                                <li>Up to {vehicle.maxluggages} suitcases</li>
                              </ul>
                            </div>
                            <button className="btn bg-gold-light-100 rounded py-3 px-4">
                              <div className="d-flex gap-2 align-items-center">
                                <p className="m-0 text-decoration-underline fw-bold">
                                  Reference
                                </p>

                                <img src={reference_icon} alt="" srcSet="" />
                              </div>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
              {loadingSearchAvailableVehicle ? (
                <p className="text-center">Loading</p>
              ) : vehicles.length > 0 ? (
                <button
                  className="btn bg-dark  rounded"
                  onClick={handleAddVehicle}
                >
                  <div className="d-flex align-items-center gap-2">
                    <img src={add_icon} alt="" srcSet="" />
                    <p className="m-0 text-white">Add Vehicle</p>
                  </div>
                </button>
              ) : (
                <p className="text-center">
                  * Fill all search form to get vehicles
                </p>
              )}

              <h5 className="mt-4">3. Additional Information</h5>
              {/* {JSON.stringify(availableVehicleData)}
              <hr />
              {JSON.stringify(vehicles)}
              <hr />
              {JSON.stringify(bookingData)} */}

              {/* AIRPORT TRANSFER */}
              {
                (bookingData?.isAirportTransfer || bookingData?.isStationTransfer) && (
                  <>
                    {(bookingData?.isFromAirport || bookingData?.isFromStation) && (
                      <div className="mt-3">
                        {
                          bookingData?.isAirportTransfer && (
                            <>
                              <p className="mb-2">Pickup Flight Number</p>
                              <AdminInput
                                placeholder={"Pickup Flight Number"}
                                prefixIcon={plane_gold_icon}
                                value={additionalInformation?.pickupFlightNumber}
                                onChange={handlePickupFlightNumber}
                              />
                            </>
                          )
                        }

                        <p className="mb-2">Add-on Meet & Assist Services</p>
                        <div className="row mb-2">
                          {/* NONE */}
                          <div className="col-4 ">
                            <div
                              className="border-gold rounded p-3 pointer"
                              style={{ height: "100px" }}
                              onClick={() =>
                                handleMeetAndAssist("arrival", {
                                  meetAssistType: "arrival",
                                  meetAssistName: "none",
                                  meetAssistPrice: 0,
                                })
                              }
                            >
                              <div className="d-flex gap-2 align-items-center h-100">
                                <img
                                  src={
                                    additionalInformation.meetAssist[0]
                                      .meetAssistName === "none"
                                      ? selected_radio_icon
                                      : unselected_radio_icon
                                  }
                                  alt=""
                                  srcSet=""
                                />
                                <div className="col">
                                  <p className="fw-bold m-0">None</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* MEET ASSIST */}
                          <div className="col-4 ">
                            <div
                              className="border-gold rounded p-3 pointer"
                              style={{ height: "100px" }}
                              onClick={() =>
                                handleMeetAndAssist("arrival", {
                                  meetAssistType: "arrival",
                                  meetAssistName: "meetAssist",
                                  meetAssistPrice: meetAssistPrice.meetAssistBasePrice,
                                })
                              }
                            >
                              <div className="d-flex gap-2 align-items-center h-100">
                                <img
                                  src={
                                    additionalInformation.meetAssist[0]
                                      .meetAssistName === "meetAssist"
                                      ? selected_radio_icon
                                      : unselected_radio_icon
                                  }
                                  alt=""
                                  srcSet=""
                                />
                                <div className="col">
                                  <p className="fw-bold m-0">Meet & Assist</p>
                                  <p className="m-0">{`${meetAssistPrice.meetAssistBasePrice.toLocaleString()} JPY`}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* MEET ASSIST VIP */}
                          <div className="col-4 ">
                            <div
                              className="border-gold rounded p-3 pointer"
                              style={{ height: "100px" }}
                              onClick={() =>
                                handleMeetAndAssist("arrival", {
                                  meetAssistType: "arrival",
                                  meetAssistName: "vipMeetAssist",
                                  meetAssistPrice:
                                    meetAssistPrice.vipMeetAssistBasePrice +
                                    (Object.values(bookingData.pax).reduce(
                                      (sum, value) => sum + value,
                                      0
                                    ) > 1
                                      ? (Object.values(bookingData.pax).reduce(
                                        (sum, value) => sum + value,
                                        0
                                      ) -
                                        1) *
                                      meetAssistPrice.vipMeetAssistAdditionalPrice
                                      : 0),
                                })
                              }
                            >
                              <div className="d-flex gap-2 align-items-center h-100">
                                <img
                                  src={
                                    additionalInformation.meetAssist[0]
                                      .meetAssistName === "vipMeetAssist"
                                      ? selected_radio_icon
                                      : unselected_radio_icon
                                  }
                                  alt=""
                                  srcSet=""
                                />
                                <div className="col">
                                  <p className="fw-bold m-0">VIP Meet & Assist</p>
                                  <p className="m-0">{`First individual: ${meetAssistPrice.vipMeetAssistBasePrice.toLocaleString()} JPY`}</p>

                                  <p className="m-0">
                                    {`Additional participants: ${meetAssistPrice.vipMeetAssistAdditionalPrice.toLocaleString()} JPY/person`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gold-FFF8E8 px-3 py-2 rounded mb-3">
                          <p className="m-0 fw-bold fs-12">
                            CANCELLATION POLICY FOR VIP MEET & ASSIST SERVICES
                          </p>
                          <p className="m-0">
                            ※ Upon confirmation of your reservation, a 100%
                            cancellation fee will apply.
                          </p>
                          <p className="m-0">
                            ※ Changes to flight details can be accommodated up to 48
                            hours before the scheduled arrival time. Regrettably, we
                            are unable to process any alterations within 48 hours of
                            the scheduled arrival time.
                          </p>
                          <p className="m-0">
                            ※ In the event of a delay to your scheduled flight, we
                            will make the necessary adjustments to our services to
                            accommodate the new arrival time
                          </p>
                        </div>
                        <div className="d-flex align-items-end mt-3 mb-2 gap-3">
                          <div className="col-2">
                            <p className="mb-2">Name Board</p>
                            <Select
                              options={PRONOUNCE_BOARD}
                              onChange={handlePronounceNameBoard}
                              defaultValue={PRONOUNCE_BOARD[0]} 
                              value={
                                PRONOUNCE_BOARD.find(
                                  (option) => option.value === additionalInformation?.pronounceNameBoard
                                ) || PRONOUNCE_BOARD[0]
                              }
                            />
                          </div>
                          <div className="col">
                            <AdminInput
                              value={additionalInformation?.nameBoard}
                              onChange={handleNameBoard}
                              mb="mb-0"
                              placeholder={"Name Board"}
                            />
                          </div>
                        </div>
                        <p className="fs-12 mb-0">
                          Your chauffeur will hold this name board upon your
                          encounter.
                        </p>
                        <p className="fs-12 mb-0 text-danger">
                          *Kindly note that for the VIP Meet & Assist service, we are
                          unable to display a pick-up sign bearing a company's name.
                        </p>
                      </div>
                    )}

                    {(bookingData?.isToAirport) && (
                      <div className="mt-3">
                        <hr />
                        {
                          bookingData?.isAirportTransfer && (
                            <>
                              <p className="mb-2">Dropoff Flight Number</p>
                              <AdminInput
                                placeholder={"Dropoff Flight Number"}
                                prefixIcon={plane_gold_icon}
                                value={additionalInformation?.dropoffFlightNumber}
                                onChange={handleDropoffFlightNumber}
                              />
                            </>
                          )
                        }

                        <p className="mb-2">Add-on Meet & Assist Services</p>
                        <div className="row mb-2">
                          {/* NONE */}
                          <div className="col-4 ">
                            <div
                              className="border-gold rounded p-3 pointer"
                              style={{ height: "100px" }}
                              onClick={() =>
                                handleMeetAndAssist("departure", {
                                  meetAssistType: "departure",
                                  meetAssistName: "none",
                                  meetAssistPrice: 0,
                                })
                              }
                            >
                              <div className="d-flex gap-2 align-items-center h-100">
                                <img
                                  src={
                                    additionalInformation.meetAssist[1]
                                      .meetAssistName === "none"
                                      ? selected_radio_icon
                                      : unselected_radio_icon
                                  }
                                  alt=""
                                  srcSet=""
                                />
                                <div className="col">
                                  <p className="fw-bold m-0">None</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* MEET ASSIST */}
                          <div className="col-4 ">
                            <div
                              className="border-gold rounded p-3 pointer"
                              style={{ height: "100px" }}
                              onClick={() =>
                                handleMeetAndAssist("departure", {
                                  meetAssistType: "departure",
                                  meetAssistName: "meetAssist",
                                  meetAssistPrice: meetAssistPrice.meetAssistBasePrice,
                                })
                              }
                            >
                              <div className="d-flex gap-2 align-items-center h-100">
                                <img
                                  src={
                                    additionalInformation.meetAssist[1]
                                      .meetAssistName === "meetAssist"
                                      ? selected_radio_icon
                                      : unselected_radio_icon
                                  }
                                  alt=""
                                  srcSet=""
                                />
                                <div className="col">
                                  <p className="fw-bold m-0">Meet & Assist</p>
                                  <p className="m-0">{`${meetAssistPrice.meetAssistBasePrice.toLocaleString()} JPY`}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* MEET ASSIST VIP */}
                          <div className="col-4 ">
                            <div
                              className="border-gold rounded p-3 pointer"
                              style={{ height: "100px" }}
                              onClick={() =>
                                handleMeetAndAssist("departure", {
                                  meetAssistType: "departure",
                                  meetAssistName: "vipMeetAssist",
                                  meetAssistPrice:
                                    meetAssistPrice.vipMeetAssistBasePrice +
                                    (Object.values(bookingData.pax).reduce(
                                      (sum, value) => sum + value,
                                      0
                                    ) > 1
                                      ? (Object.values(bookingData.pax).reduce(
                                        (sum, value) => sum + value,
                                        0
                                      ) -
                                        1) *
                                      meetAssistPrice.vipMeetAssistAdditionalPrice
                                      : 0),
                                })
                              }
                            >
                              <div className="d-flex gap-2 align-items-center h-100">
                                <img
                                  src={
                                    additionalInformation.meetAssist[1]
                                      .meetAssistName === "vipMeetAssist"
                                      ? selected_radio_icon
                                      : unselected_radio_icon
                                  }
                                  alt=""
                                  srcSet=""
                                />
                                <div className="col">
                                  <p className="fw-bold m-0">VIP Meet & Assist</p>
                                  <p className="m-0">{`First individual: ${meetAssistPrice.vipMeetAssistBasePrice.toLocaleString()} JPY`}</p>

                                  <p className="m-0">
                                    {`Additional participants: ${meetAssistPrice.vipMeetAssistAdditionalPrice.toLocaleString()} JPY/person`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gold-FFF8E8 px-3 py-2 rounded mb-3">
                          <p className="m-0 fw-bold fs-12">
                            CANCELLATION POLICY FOR VIP MEET & ASSIST SERVICES
                          </p>
                          <p className="m-0">
                            ※ Upon confirmation of your reservation, a 100%
                            cancellation fee will apply.
                          </p>
                          <p className="m-0">
                            ※ Changes to flight details can be accommodated up to 48
                            hours before the scheduled arrival time. Regrettably, we
                            are unable to process any alterations within 48 hours of
                            the scheduled arrival time.
                          </p>
                          <p className="m-0">
                            ※ In the event of a delay to your scheduled flight, we
                            will make the necessary adjustments to our services to
                            accommodate the new arrival time
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )
              }

              <div className="d-flex align-items-end mt-4 mb-2 gap-3">
                <div className="col-2">
                  <p className=" mb-2">Passenger’s Name</p>
                  <Select
                    options={PRONOUNCE}
                    onChange={handlePronouncePassangerName}
                    defaultValue={PRONOUNCE[0]} // Autofill with the first option as default
                    value={
                      PRONOUNCE.find(
                        (option) => option.value === additionalInformation?.pronounce
                      ) || PRONOUNCE[0]
                    }
                  ></Select>
                </div>
                <div className="col">
                  <AdminInput
                    value={additionalInformation?.passengerName}
                    onChange={handlePassangerName}
                    mb="mb-0"
                    placeholder="Passenger's Name"
                  />
                </div>
              </div>

              {/* CHILD SEAT */}
              {["child", "toddler", "infant"].some(
                (key) => bookingData?.pax[key] > 0
              ) && (
                  <div className="">
                    <div className="d-flex mt-4 justify-content-between">
                      <p>Child Seats & Booster Seats</p>
                      <p>1,100 JPY</p>
                    </div>
                    <p className="fs-12">
                      *The images shown are for illustration purposes only and may
                      not be an exact representation of the product
                    </p>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="border rounded p-3">
                          <img
                            src={infant_seat}
                            className="mb-3"
                            alt=""
                            srcSet=""
                            style={{ height: "121 px" }}
                          />
                          <p
                            className="noto mb-1 fs-8"
                            style={{ letterSpacing: "3px" }}
                          >
                            CHILD SEAT
                          </p>
                          <h5>Baby</h5>
                          <p className="fs-12 mb-0 text-A7A7A7">
                            Usage Recommendation
                          </p>
                          <p className="fs-12">For babies</p>
                          <div className="d-flex gap-4 align-items-center">
                            <button
                              type="button"
                              className="btn btn-circle btn-outline-dark"
                              onClick={() => {
                                if (
                                  additionalInformation.childSeat[3]
                                    .childSeatCount > 0
                                ) {
                                  handleChildSeat(
                                    "Baby",
                                    additionalInformation.childSeat[3]
                                      .childSeatCount - 1
                                  );
                                }
                              }}
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <p className="m-0">
                              {additionalInformation.childSeat[3].childSeatCount}
                            </p>
                            <button
                              type="button"
                              className="btn btn-circle btn-outline-dark"
                              onClick={() =>
                                handleChildSeat(
                                  "Baby",
                                  additionalInformation.childSeat[3]
                                    .childSeatCount + 1
                                )
                              }
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="border rounded p-3">
                          <img
                            src={baby_seat}
                            className="mb-3"
                            alt=""
                            srcSet=""
                            style={{ height: "121 px" }}
                          />
                          <p
                            className="noto mb-1 fs-8"
                            style={{ letterSpacing: "3px" }}
                          >
                            CHILD SEAT
                          </p>
                          <h5>For Infant</h5>
                          <p className="fs-12 mb-0 text-A7A7A7">
                            Usage Recommendation
                          </p>
                          <p className="fs-12">For children from 0-2 years</p>
                          <div className="d-flex gap-4 align-items-center">
                            <button
                              type="button"
                              className="btn btn-circle btn-outline-dark"
                              onClick={() => {
                                if (
                                  additionalInformation.childSeat[0]
                                    .childSeatCount > 0
                                ) {
                                  handleChildSeat(
                                    "Infant",
                                    additionalInformation.childSeat[0]
                                      .childSeatCount - 1
                                  );
                                }
                              }}
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <p className="m-0">
                              {additionalInformation.childSeat[0].childSeatCount}
                            </p>
                            <button
                              type="button"
                              className="btn btn-circle btn-outline-dark"
                              onClick={() =>
                                handleChildSeat(
                                  "Infant",
                                  additionalInformation.childSeat[0]
                                    .childSeatCount + 1
                                )
                              }
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="border rounded p-3">
                          <img
                            src={toddler_seat}
                            className="mb-3"
                            alt=""
                            srcSet=""
                            style={{ height: "121 px" }}
                          />
                          <p
                            className="noto mb-1 fs-8"
                            style={{ letterSpacing: "3px" }}
                          >
                            CHILD SEAT
                          </p>
                          <h5>Toddler</h5>
                          <p className="fs-12 mb-0 text-A7A7A7">
                            Usage Recommendation
                          </p>
                          <p className="fs-12">For children from 1 - 3 Years</p>
                          <div className="d-flex gap-4 align-items-center">
                            <button
                              type="button"
                              className="btn btn-circle btn-outline-dark"
                              onClick={() => {
                                if (
                                  additionalInformation.childSeat[1]
                                    .childSeatCount > 0
                                ) {
                                  handleChildSeat(
                                    "Toddler",
                                    additionalInformation.childSeat[1]
                                      .childSeatCount - 1
                                  );
                                }
                              }}
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <p className="m-0">
                              {additionalInformation.childSeat[1].childSeatCount}
                            </p>
                            <button
                              type="button"
                              className="btn btn-circle btn-outline-dark"
                              onClick={() =>
                                handleChildSeat(
                                  "Toddler",
                                  additionalInformation.childSeat[1]
                                    .childSeatCount + 1
                                )
                              }
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="border rounded p-3">
                          <img
                            src={child_seat}
                            className="mb-3"
                            alt=""
                            srcSet=""
                            style={{ height: "121 px" }}
                          />
                          <p
                            className="noto mb-1 fs-8"
                            style={{ letterSpacing: "3px" }}
                          >
                            CHILD SEAT
                          </p>
                          <h5>Child</h5>
                          <p className="fs-12 mb-0 text-A7A7A7">
                            Usage Recommendation
                          </p>
                          <p className="fs-12">For children from 4 - 11 Years</p>
                          <div className="d-flex gap-4 align-items-center">
                            <button
                              type="button"
                              className="btn btn-circle btn-outline-dark"
                              onClick={() => {
                                if (
                                  additionalInformation.childSeat[2]
                                    .childSeatCount > 0
                                ) {
                                  handleChildSeat(
                                    "Child",
                                    additionalInformation.childSeat[2]
                                      .childSeatCount - 1
                                  );
                                }
                              }}
                            >
                              <i className="bi bi-dash"></i>
                            </button>
                            <p className="m-0">
                              {additionalInformation.childSeat[2].childSeatCount}
                            </p>
                            <button
                              type="button"
                              className="btn btn-circle btn-outline-dark"
                              onClick={() =>
                                handleChildSeat(
                                  "Child",
                                  additionalInformation.childSeat[2]
                                    .childSeatCount + 1
                                )
                              }
                            >
                              <i className="bi bi-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              <h5 className="mt-4">4. Notes (displayed on the quotation)</h5>
              <AdminTextArea
                value={note}
                placeholder={"Itinerary details or additional notes"}
                onChange={handleNote}
              />

              <h5 className="mt-4">Transfer Recurrence</h5>
              <div className="d-flex gap-3 my-3">
                <div
                  className={`border ${addTransferType === "one_time" && "border-gold"
                    } rounded p-2 pointer`}
                  onClick={() => handleChangeAddTransferType("one_time")}
                >
                  <div className="d-flex gap-3">
                    <img
                      src={
                        addTransferType === "one_time"
                          ? selected_radio_icon
                          : unselected_radio_icon
                      }
                      alt=""
                      srcSet=""
                    />
                    <p className="m-0 p-0">One-Time</p>
                  </div>
                </div>
                <div
                  className={`border ${addTransferType === "recurring" && "border-gold"
                    } rounded p-2 pointer`}
                  onClick={() => handleChangeAddTransferType("recurring")}
                >
                  <div className="d-flex gap-3">
                    <img
                      src={
                        addTransferType === "recurring"
                          ? selected_radio_icon
                          : unselected_radio_icon
                      }
                      alt=""
                      srcSet=""
                    />
                    <p className="m-0 p-0">Recurring</p>
                  </div>
                </div>
              </div>
              {addTransferType === "recurring" && (
                <div className="row mb-3">
                  {recurringTravelDates.map((item, index) => (
                    <div className="col-6 mb-2 px-1">
                      <AdminInputDatePicker
                        placeholder="Select Issue Date"
                        prefixIcon={date_icon}
                        onSelectDate={handleSelectTravelDate}
                        mb={""}
                        name="issueDate"
                        hasIndex={true}
                        index={index}
                        value={
                          recurringTravelDates[index] != null
                            ? moment(
                              recurringTravelDates[index],
                              "YYYY-MM-DD"
                            ).toDate()
                            : null
                        }
                        suffixIcon={index !== 0 && input_delete_x_icon}
                        onSufixIconClick={() => removeReccuringTravelDate(index)}
                      />
                    </div>
                  ))}
                  <div className="col-auto px-1">
                    <div className="d-grid">
                      <button className="bg-gold-light-100 px-4 rounded" style={{ height: "40px" }} onClick={addReccuringTravelDate}>
                        <div className="d-flex justify-content-center align-items-center gap-2">

                          <img src={plus_icon} alt="" srcSet="" />
                          <p className="m-0">
                            Add Date
                          </p>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {vehicles.filter(value => value !== null).length > 0 ? parseInt(bookingData?.luggage?.cabinSize + bookingData?.luggage?.checkinSize) > totalMaxLuggage || parseInt(bookingData?.pax?.adult + bookingData?.pax?.child + bookingData?.pax?.toddler + bookingData?.pax?.baby) > totalMaxPessanger ? <></> : <div className="d-grid mt-3">
                <button
                  className="btn btn-primary py-2 rounded"
                  disabled={loadingAddTransfer}
                  onClick={handleSubmitConfirm}
                >
                  {loadingAddTransfer
                    ? "Loading ... "
                    : "Confirm & Calculate Price "}
                </button>
              </div> : <></>}
            </div>
          </div>


          <AdminModal
            show={errorModal}
            size="modal-md"
            centered={true}
            onClose={closeErrorModal}
            title={"ERROR"}
          >
            <h5 className="text-center text-EB001B">
              {errorText}
            </h5>
          </AdminModal>
        </main>
      </Layout>
    </>
  );
};

export default Transfer;