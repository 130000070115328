import {
    ADD_QUOTATION,
    GET_QUOTATIONS,
    GET_QUOTATION,
    QUOTATION_ERROR,
} from "../../actions/types";

const initialState = {
    quotations: null,
    quotation: null,
    loading: true,
    error: {},
};

export default function foo(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case ADD_QUOTATION:
            return {
                ...state,
                quotation: payload,
                loading: false,
            };
        case GET_QUOTATION:
            return {
                ...state,
                quotation: payload,
                loading: false,
            };
        case GET_QUOTATIONS:
            return {
                ...state,
                quotations: payload,
                loading: false,
            };
        case QUOTATION_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            };
        default:
            return state;
    }
}
