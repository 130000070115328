
const AdminInput = ({type = "text", prefixIcon = null, suffixIcon = null, placeholder = "", mb = "mb-3", onChange, value , name = "",  label = null, disable = false}) => {
  return (
    <>
      <div className={mb}>
        {label && <label className="mb-1">{label}</label> }
        <div className="w-100 rounded border">
          <div className="d-flex gap-2 input-container">
            {prefixIcon && (
              <img src={prefixIcon} alt="" srcSet="" className="ms-3" />
            )}
            <input className={`input-field w-100 rounded border-0 py-2 no-calendar ${prefixIcon != null   ? '' : 'px-3'} ${disable && 'bg-grey-light'}`} type={type} placeholder={placeholder}  
             onChange={(e) => onChange(e)} name={name}  value={value} disabled={disable} />
            {suffixIcon && (
              <img src={suffixIcon} alt="" srcSet="" className="me-3" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminInput;
